// Colours
const colours = {

    primary: '#ff6625',
    primaryDark: '#ff4c00',
    primaryDarker: '#cc3d00',
    primaryDarkest: '#782400',
    primaryLight: '#ff7e47',
    primaryLighter: '#ffb697',
    primaryLightest: '#ffd9c9',

    secondary: '#346b62',
    secondaryDark: '#25524b',
    secondaryDarker: '#1c4c43',
    secondaryDarkest: '#003c32',
    secondaryLight: '#739e98',
    secondaryLighter: '#a4c8c2',
    secondaryLightest: '#dae8e7',

    bg: '#4a7a73',
    bgDark: '#365e58',
    bgDarker: '#2b554e',
    bgDarkest: '#0b3f36',
    bgLight: '#739e98',
    bgLighter: '#a4c8c2',
    bgLightest: '#dae8e7',


    // primary: '#cd9b57',
    // primaryDark: '#b4874a',
    // primaryDarker: '#906d3d',
    // primaryDarkest: '#544024',
    // primaryLight: '#d5ab71',
    // primaryLighter: '#e4cfb3',
    // primaryLightest: '#ede5db',

    // bg: '#f9f9f9',
    // bgDark: '#f8f8f8',
    // bgDarker: '#f6f6f6',
    // bgDarkest: '#f5f5f5',
    // bgLight: '#fbfbfb',
    // bgLighter: '#fdfdfd',
    // bgLightest: '#ffffff',


    // secondary: '#11375f',
    // secondaryDark: '#0e253c',
    // secondaryDarker: '#0c1d30',
    // secondaryDarkest: '#0c1d32',
    // secondaryLight: '#234b74',
    // secondaryLighter: '#8599ae',
    // secondaryLightest: '#c3cad2',

    tertiary: '#11375f',
    tertiaryDark: '#0e253c',
    tertiaryDarker: '#0c1d30',
    tertiaryDarkest: '#07111c',
    tertiaryLight: '#234b74',
    tertiaryLighter: '#8599ae',
    tertiaryLightest: '#c3cad2',



    brand: '#cd9b57',
    brandDark: '#b4874a',
    brandLight: '#d5ab71',
    brandLightExtra: '#e4cfb3',

    black: '#000000',
    blackSecondary: '#212121',
    white: '#ffffff',
    greyBG: '#d3d3d3',

    blueGreyLight: '#eceff1',
    indigoLight: '#e8eaf6',
    brownLight: '#efebe9',

    greyLight: '#fafafa',
    greyFirst: '#f5f5f5',
    greySecond: '#eeeeee',
    greyThird: '#e0e0e0',
    greyFour: '#bdbdbd',
    greyFive: '#9e9e9e',
    greyDark: '#707070'

};
export default colours;
