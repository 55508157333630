import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Animated, StyleSheet, View, Pressable, Button, Image, Text, TouchableOpacity, Linking, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView } from 'react-native';

import colours from '../config/colours';

import Mobile from '../assets/images/social/mobileIcon.svg'

import Form from '../assets/images/social/mail.svg'
import WhatsApp from '../assets/images/social/WhatsappIcon.svg';

import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';
import fonts from '../config/fonts'
import Menu from './menu';
// const { height, width } = Dimensions.get('window')

const Floating = ({ animatedValue, pageNumber }) => {

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }



    const { width, height } = dimensions;

    useEffect(() => {
        window.scrollTo(0, 0)
        window.addEventListener("resize", handleResize, false);
        return () => window.removeEventListener("resize", handleResize);

        //firebase.analytics();
        // eslint-disable-next-line
    }, [width, height]);


    const HEADER_HEIGHT = width > 1000 ? 80 : 2;
    const HEADER_HEIGHTM = width > 1000 ? 60 : 45;
    const HEADER_PADDING = width > 1000 ? 20 : 10;;


    const insets = useSafeAreaInsets();
    const headerHeight = animatedValue.interpolate({
        inputRange: [0, HEADER_HEIGHT + insets.top],
        outputRange: [HEADER_HEIGHT + insets.top, insets.top + HEADER_HEIGHTM],
        extrapolate: 'clamp'
    });

    const headerPadding = animatedValue.interpolate({
        inputRange: [0, HEADER_PADDING + insets.top],
        outputRange: [HEADER_PADDING + insets.top, insets.top + 0],
        extrapolate: 'clamp'
    });



    const navigation = useNavigation();
    const [isModalVisibleContact, setModalVisibleContact] = useState(false);
    const toggleModal = () => {
        setModalVisibleContact(!isModalVisibleContact);
    };

    //STYLES
    const styles = StyleSheet.create({
        container: {
            position: 'absolute',
            bottom: 0,

        },
        containerCenter: {
            alignSelf: 'center'

        },
        containerMain: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        containerLogo: {
            //paddingLeft: width > 1000 ? 20 : 5,

        },

        containerMenu: {
            flexDirection: 'row',

        },
        containerMenuMobile: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
        },
        containerMenuLeft: {
            flex: 1,
            alignItems: 'flex-start',
        },
        containerLogoM: {
            flex: 4,
            justifyContent: 'center',
            alignItems: 'center',
        },
        containerMenuRight: {
            flex: 1,
            alignItems: 'flex-end',
        },

        containerMenuText: {
            color: colours.primary,
            fontWeight: '200',
            fontSize: 16,
            marginHorizontal: 10,
            paddingVertical: 5,
            fontFamily: fonts.primary,
        },
        hovered: {
            // backgroundColor: 'rgba(255,0,0,0.2)',
            // borderRadius: 4,
            borderBottomWidth: 1,
            borderBottomColor: colours.primary,
            marginBottom: -2,


        },
        modalContainer: {
            flex: 1,
            flexDirection: 'column',
            backgroundColor: colours.white,
            justifyContent: 'flex-start',
            width: '80%',
            margin: 0,
        },

    });
    //STYLES


    return (
        <Animated.View style={[styles.container, {
            width: width,
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
          //  paddingHorizontal: headerPadding,
            paddingHorizontal: 10,

           // height:width > 1000? 0:headerHeight,
            height:width > 1000? 0:45,

            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: colours.white,

                  shadowColor: colours.black,
      shadowOffset: { width: 0, height: 3, },
      shadowOpacity: 0.2,
      shadowRadius: 20,
      elevation: 5,

        }]}>
            <View style={[styles.containerCenter, {
                maxWidth: 1280, width: width > 1000 ? '85%' : '95%',
            }]}>


                <View>



                {width < 1000  &&

                    // {width < 1000 && pageNumber > 0 &&
                        <View style={styles.containerMenuMobile}>

                            <View style={styles.containerMenuLeft}>
                            <TouchableOpacity style={styles.submitButton} onPress={() => Linking.openURL('tel:+971568225126')} >
                                    <Image source={Mobile} style={{ width: 40, height: 40 }} />
                                </TouchableOpacity>
                            </View>
                            
                            <TouchableOpacity style={styles.submitButton} onPress={() => Linking.openURL('mailto:info@soknarealestate.com')} >
                                    <Image source={Form} style={{ width: 40, height: 35, resizeMode:'contain', marginBottom:5,}} />
                                </TouchableOpacity>

                            <View style={styles.containerMenuRight}>
                            <TouchableOpacity style={styles.submitButton} onPress={() => Linking.openURL('https://api.whatsapp.com/send?phone=971568225126')} >
                                <View> <Image source={WhatsApp} style={{ width: 40, height: 40 }} /></View>
                            </TouchableOpacity>
                            </View>

                        </View>
                    }


                </View>




                <Modal
                    isVisible={isModalVisibleContact}
                    transparent={true}
                    onBackdropPress={() => setModalVisibleContact(!isModalVisibleContact)}
                    animationIn={'slideInLeft'}
                    animationOut={'slideOutLeft'}
                    style={styles.modalContainer}
                >
                    <View>
                        <Menu
                            toggleModal={toggleModal}
                        />
                    </View>
                </Modal>


            </View>
        </Animated.View>

    );
};






export default Floating;