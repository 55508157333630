import React from 'react';
import { StyleSheet,View,Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl,ScrollView,SafeAreaView , Linking} from 'react-native';

import colours from '../../config/colours';
import fonts from '../../config/fonts'


const { height, width } = Dimensions.get('window')

const LeadFormFooter = () => {

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  const {width, height} = dimensions;

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize);

    //firebase.analytics();
    // eslint-disable-next-line
  }, [width, height]);


 
    return (
      <View>

      <View style={styles.container}>

      <Text numberOfLines={1} style={styles.footerText} >
                        © Copyright  {new Date().getFullYear()} | PDC CONSULT 
                    </Text>
      

                    <Text numberOfLines={1} style={styles.footerText} >
                        All rights reserved | Memeber of <TouchableOpacity 
                onPress={() => Linking.openURL('https://pdcgroupconsulting.com')}

                    >
                  <Text style={styles.footerLink}>PDC Group</Text>
                </TouchableOpacity>
                    </Text>

      </View>
      </View>

    );
  };



const styles = StyleSheet.create({
  container: {
    backgroundColor: colours.greyThird,
    paddingTop:25,
    paddingBottom:40,

  },
  footerText: {
    fontSize: width>1000?14:10,
    color: colours.blackSecondary,
    textAlign: 'center',
    fontWeight: '300',
    fontFamily:fonts.primary
  },

  footerLink: {
    fontSize: width>1000?14:10,
    fontWeight: '500',
    fontFamily:fonts.primary
  },


});
  

export default LeadFormFooter;