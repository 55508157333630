import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, Pressable, Button, Image, Text, TouchableOpacity, Linking, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView, ImageBackground } from 'react-native';

import colours from '../config/colours';
import imgBanner from '../assets/images/home/banner-bg.jpg'

import { useNavigation } from '@react-navigation/native';
import fonts from '../config/fonts'
const { height, width } = Dimensions.get('window')

const HomeBanner = () => {
    const navigation = useNavigation();

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
      })
      const handleResize = () => {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    
      const {width, height} = dimensions;
    
      useEffect(() => {
        window.scrollTo(0, 0)
        window.addEventListener("resize", handleResize, false);
        return () => window.removeEventListener("resize", handleResize);
        //firebase.analytics();
        // eslint-disable-next-line
      }, [width, height]);
    
    

    return (

        <View style={styles.containerMain}>
            <View style={styles.containerBanner}>
                <ImageBackground source={{ uri: imgBanner }} width={'100%'} style={{alignSelf:'center', width:1920, height: width > 1000 ? 500 : 350 }}>
                    <ImageBackground width={'100%'} style={{ 
                        height: width > 1080 ? 500 : 400, 
                        backgroundColor: 'rgba(5,0,0,0.2)' 
                        }}>

                        <View style={styles.containerTitle}>
                            <Text style={styles.textTitle}>Unlock best deals</Text>
                            <Text style={styles.textSubTitle}>Start your properties search here</Text>
                        </View>

                    </ImageBackground>
                </ImageBackground>

            </View>

        </View>

    );
};



const styles = StyleSheet.create({

    containerMain: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    containerBanner: {
        flex: 1,
        alignSelf: 'center',
        backgroundColor:colours.secondary
    },

    containerTitle: {
        flex: 1,
        justifyContent: 'center',
        alignSelf: 'center',
        maxWidth: width > 1080 ? 800:width-20,


    },
    textTitle: {
        color: colours.white,
        fontWeight: '600',
        textAlign: 'center',
        fontSize: width > 1000 ? 40 : 22,
        lineHeight: width > 1000 ? 44 : 30,
        marginHorizontal: 10,
        padding: 5,
        fontFamily: fonts.primary
    },
    textSubTitle: {
        marginTop: 20,
        color: colours.white,
        fontWeight: '200',
        lineHeight: width > 1000 ? 34 : 20,
        textAlign: 'center',
        fontSize: width > 1000 ? 22 : 16,
        marginHorizontal: 10,
        padding: 5,
        fontFamily: fonts.primary
    },
    hovered: {
        backgroundColor: colours.blackSecondary,
        borderRadius: 4,
        //padding:5
    },

});


export default HomeBanner;