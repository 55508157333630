import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Animated, StyleSheet, View, Pressable, Button, Image, Text, TouchableOpacity, Linking, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView } from 'react-native';

import colours from '../config/colours';
import SoknaLogo from '../assets/images/logo/sokna.svg'
import SoknaLogoW from '../assets/images/logo/sokna2.svg'

import MenuHamburger from '../assets/images/icons/menu.svg'
import MenuHamburgerB from '../assets/images/icons/menuB.svg'


import Call from '../assets/images/social/call.svg'
import Callw from '../assets/images/social/call.svg'

import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';
import fonts from '../config/fonts'
import Menu from './menu';
import { ImageBackground } from 'react-native-web';
// const { height, width } = Dimensions.get('window')

const Header = ({ animatedValue, pageNumber }) => {

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }



  const { width, height } = dimensions;

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize);

    //firebase.analytics();
    // eslint-disable-next-line
  }, [width, height]);


  const HEADER_HEIGHT = width > 1000 ? 80 : 65;
  const HEADER_HEIGHTM = width > 1000 ? 60 : 45;
  const HEADER_PADDING = width > 1000 ? 20 : 10;;


  const insets = useSafeAreaInsets();
  const headerHeight = animatedValue.interpolate({
    inputRange: [0, HEADER_HEIGHT + insets.top],
    outputRange: [HEADER_HEIGHT + insets.top, insets.top + HEADER_HEIGHTM],
    extrapolate: 'clamp'
  });

  const headerPadding = animatedValue.interpolate({
    inputRange: [0, HEADER_PADDING + insets.top],
    outputRange: [HEADER_PADDING + insets.top, insets.top + 0],
    extrapolate: 'clamp'
  });



  const navigation = useNavigation();
  const [isModalVisibleContact, setModalVisibleContact] = useState(false);
  const toggleModal = () => {
    setModalVisibleContact(!isModalVisibleContact);
  };

  //STYLES
  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,

    },
    containerCenter: {
      alignSelf: 'center'

    },
    containerMain: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    containerLogo: {
      //paddingLeft: width > 1000 ? 20 : 5,

    },

    containerMenu: {
      flexDirection: 'row',

    },
    containerMenuMobile: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    containerMenuLeft: {
      flex: 1,
      alignItems: 'flex-start',
    },
    containerLogoM: {
      flex: 4,
      justifyContent: 'center',
      alignItems: 'center',
    },
    containerMenuRight: {
      flex: 1,
      alignItems: 'flex-end',
    },

    containerMenuText: {
      color: colours.primary,
      fontWeight: pageNumber > 0 ? '400' : '300',
      fontSize: pageNumber > 0 ? 14 : 17,
      marginHorizontal: 10,
      paddingVertical: 5,
      fontFamily: fonts.primary,
    },
    hovered: {
      // backgroundColor: 'rgba(255,0,0,0.2)',
      // borderRadius: 4,
      borderBottomWidth: 1,
      borderBottomColor: colours.primary,
      marginBottom: -2,


    },
    modalContainer: {
      flex: 1,
      flexDirection: 'column',
      backgroundColor: colours.white,
      justifyContent: 'flex-start',
      width: '80%',
      margin: 0,
    },

  });
  //STYLES


  return (
    <Animated.View style={[styles.container, {
      width: width,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 10,
      paddingHorizontal: headerPadding,
      // paddingBottom:headerHeight*.2,
      height: headerHeight,
      flexDirection: 'row',
      justifyContent: 'center',
      //  backgroundColor:pageNumber>0?'rgba(255,76,0,0.01)':colours.secondaryDarkest,
      backgroundColor: pageNumber > 0 ? colours.white : colours.secondaryDarkest,


      shadowColor: colours.black,
      shadowOffset: { width: 0, height: 3, },
      shadowOpacity:pageNumber > 0? 0.2:0,
      shadowRadius: 20,
      elevation: 5,
    }]}>
      <View style={[styles.containerCenter, {
        maxWidth: 1280, width: width > 1000 ? '85%' : '95%',
      }]}>


        <View>

          {width > 1000 &&
            <View style={styles.containerMain}>
              <View style={styles.containerLogo}>
                <TouchableOpacity onPress={() => navigation.navigate('Home')}>
                  <img src={pageNumber > 0 ? SoknaLogoW : SoknaLogo} width={pageNumber > 0 ? 130 : 170} height={80} />
                </TouchableOpacity>
              </View>

              <View style={styles.containerMenu}>

              <TouchableOpacity onPress={() => navigation.navigate('About')}>
                <Text style={styles.containerMenuText}>
                  {/* <Pressable onPress={() => navigation.navigate('About')} style={(state) => state.hovered && styles.hovered} > */}
                    {'ABOUT'}
                </Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => navigation.navigate('Services')}>
                <Text style={styles.containerMenuText}>
                    {'SERVICES'}
                </Text>
                </TouchableOpacity>


                <TouchableOpacity onPress={() => navigation.navigate('Properties')}>

                <Text style={styles.containerMenuText}>
                    {'PROPERTIES'}
                </Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => navigation.navigate('Careers')}>

                <Text style={styles.containerMenuText}>
                    {'CAREER'}
                </Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => navigation.navigate('Contact')}>

                <Text style={styles.containerMenuText}>
                    {'CONTACT'}
                </Text>
                </TouchableOpacity>



              </View>
            </View>
          }





          {width < 1000 &&
            <View style={styles.containerMenuMobile}>

              <View style={styles.containerMenuLeft}>
                <TouchableOpacity onPress={() => { setModalVisibleContact(!isModalVisibleContact) }}>
                  <img src={pageNumber > 0 ? MenuHamburgerB : MenuHamburger} width={40} height={30} />
                </TouchableOpacity>
              </View>
              <View style={styles.containerLogoM}>
                <TouchableOpacity onPress={() => navigation.navigate('Home')}>
                  <img src={pageNumber > 0 ? SoknaLogoW : SoknaLogo} width={160} height={pageNumber > 0 ? 40 : 50} />
                </TouchableOpacity>
              </View>

              <View style={styles.containerMenuRight}>
                {/* <TouchableOpacity
                  onPress={() => navigation.navigate('Contact')}
                >
                  <img src={Call} width={35} height={27} />
                </TouchableOpacity> */}
              </View>

            </View>
          }






        </View>




        <Modal
          isVisible={isModalVisibleContact}
          transparent={true}
          onBackdropPress={() => setModalVisibleContact(!isModalVisibleContact)}
          animationIn={'slideInLeft'}
          animationOut={'slideOutLeft'}
          style={styles.modalContainer}
        >
          <View>
            <Menu
              toggleModal={toggleModal}
            />
          </View>
        </Modal>


      </View>
    </Animated.View>

  );
};






export default Header;