import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView, Linking } from 'react-native';

import colours from '../config/colours';
import fonts from '../config/fonts'
import { useNavigation, useRoute } from '@react-navigation/native';
import Modal from 'react-native-modal';
import LeadFormFill from '../pages/promotions/leadFormFill';
import FormRequestCallback from '../pages/promotions/formRequestCallback';


const { height, width } = Dimensions.get('window')

const FooterWidgets = () => {
  const navigation = useNavigation();
  const route = useRoute()
  const [dimensions, setDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth })
  const handleResize = () => { setDimensions({ width: window.innerWidth, height: window.innerHeight, }); }

  const { width, height } = dimensions;

  useEffect(() => {
    window.scrollTo(0, 0),
      window.addEventListener("resize", handleResize, false)
    return () => window.removeEventListener("resize", handleResize)

    //firebase.analytics();
    // eslint-disable-next-line
  }, [width, height]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const toggleModal = () => {
    setIsModalVisible(!isModalVisible)
  }
  const styles = StyleSheet.create({

    containerCallAction: {
      marginTop: 50,
      backgroundColor: colours.secondaryDarkest,
      paddingVertical: 20,
      justifyContent: 'center',
      alignItems: 'center'

    },
    textCallAction: {
      color: colours.white,
      fontSize: width > 1000 ? 25 : 20,
      fontWeight: '300',
      fontFamily: fonts.primary,
      textAlign: 'center',

    },
    containerCallActionButtons: {
      //backgroundColor: 'rgba(5,0,0,0.2)',
      backgroundColor:colours.primaryDarker,
      alignSelf: 'center',
      // borderBottomColor: colours.brand,
      // borderBottomWidth: 2,
      paddingHorizontal: 15,
      paddingVertical: 10,
      marginHorizontal: 20,

      marginTop: 10,
      borderRadius: 5
    },
    textCallActionButton: {
      color: colours.white,
      fontSize: width > 1000 ? 18 : 15,
      fontWeight: '300',
      fontFamily: fonts.primary,
      textAlign: 'center',
    },

    textFooterLinks: {
      fontSize: width > 1000 ? 14 : 13,
      lineHeight: 27,
      color: colours.blackSecondary,
      textAlign: 'center',
      fontWeight: '300',
      fontFamily: fonts.primary,
      paddingHorizontal: 20,
    },
    containerMain: {
      backgroundColor: colours.greySecond,
      flexDirection: 'row',
      justifyContent: 'center',
    },

    containerMainMobile: {
      backgroundColor: colours.greySecond,
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 20,

    },
    container: {
      backgroundColor: colours.greySecond,
      flexDirection: width > 1000 ? 'row' : 'column',
      alignItems: 'center',
      paddingVertical: 20
    },
    containerIcon: {
      marginHorizontal: 4,
      alignItems: 'center'
    },
    containerSocial: {
      backgroundColor: colours.greySecond,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 10,
    },
    closeContainer: {
      backgroundColor: 'darkred',
      alignItems: 'center',
      borderRadius: 5,
      paddingHorizontal: 20,
      paddingVertical: 5,
    },
    closeButtonContainer: {
      flex: 1,
      alignItems: 'center',
      color: 'red',
      marginBottom: 20,

    },
    closeText: {
      fontSize: 30,
      fontWeight: '700',
      color: colours.white,
    },


  });
  return (
    <View >
      {route.name !== 'Contact' &&
        <View style={[styles.containerCallAction, { flexDirection: width > 1275 ? 'row' : 'column', justifyContent: 'center' }]}>
          <Text style={styles.textCallAction} >
            {'Looking to know more ?'}
          </Text>
          <TouchableOpacity onPress={() => setIsModalVisible(true)}>
            <View style={styles.containerCallActionButtons}>
              <Text style={styles.textCallActionButton} >
                {'Request A Callback'}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      }
      {route.name == 'Contact' &&
        <View style={[styles.containerCallAction, { height: 70 }]}></View>
      }

      <View style={width > 1000 ? styles.containerMain : styles.containerMainMobile}>
        <View style={styles.container}>

          <TouchableOpacity onPress={() => navigation.navigate('About')}>
            <Text style={styles.textFooterLinks} >
             {'About Us'}
            </Text>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => navigation.navigate('Services')}>
            <Text style={styles.textFooterLinks} >
               {'Services'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate('Properties')}>
            <Text style={styles.textFooterLinks} >
              {'Properties'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate('Careers')}>
            <Text style={styles.textFooterLinks} >
              {'Careers'}
            </Text>
          </TouchableOpacity>
          {route.name !== 'Contact' &&
            <TouchableOpacity onPress={() => navigation.navigate('Contact')}>
              <Text style={styles.textFooterLinks} >
                {'Contact Us'}
              </Text>
            </TouchableOpacity>
          }
        </View>


      </View>

      <Modal
        isVisible={isModalVisible}
        transparent={true}
        onBackdropPress={() => setIsModalVisible(false)}
        animationIn={'zoomInDown'}
        animationOut={'zoomOutDown'}
        style={styles.modalContainer}
       
        
      >

        <View>
          <FormRequestCallback  toggleButton={toggleModal}/>
          {/* <View style={styles.closeButtonContainer} >
            <TouchableOpacity
              onPress={() => setIsModalVisible(false)}
            >
              <View style={styles.closeContainer}>
                <Text style={styles.closeText}>X</Text>
              </View>

            </TouchableOpacity>
          </View> */}
        </View>

      </Modal>

    </View>

  );
};





export default FooterWidgets;