

import React, { useState, useEffect } from "react";
import { View, Text, Image, TextInput, ImageBackground, Dimensions, Button, StyleSheet, ScrollView, TouchableOpacity, Linking } from "react-native";
import colours from '../../config/colours'
import "typeface-lato";
// import { firebase } from '../../config/firebase'
import fonts from '../../config/fonts'


import { firebase, leadfirebase } from '../../config/firebase'



const { height, width } = Dimensions.get('window')

const LeadFormFill = ({pageName}) => {



    const [loading, setLoading] = useState(false);

    const [onFocus, setOnFocus] = useState(false)
    const [onBlur, setOnBlur] = useState(false)

    const [onFocusNumber, setOnFocusNumber] = useState(false)
    const [onBlurNumber, setOnBlurNumber] = useState(false)

    const [nameText, setNameText] = useState('')
    const [numberText, setNumberText] = useState('')
    const [messageText, setMessageText] = useState('')
    const [contactText, setcontactText] = useState('');


    const whatsappURL = 'https://api.whatsapp.com/send?phone=971568225126'


    const contactUsData = () => {

        var currentDate = new Date();
        var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
        var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
        var createdDateTo = currentDate.getFullYear() + twoDigitMonth + twoDigitDate;
        const docid = createdDateTo + "-" + new Date().getTime()
        const leadid = new Date().getTime()



        leadfirebase.firestore().collection('leads')
            .doc(docid).set({
                ad: 'Website',
                adset: pageName,
                category: 'New',
                contact: numberText,
                name: nameText,
                email: contactText,
                date: (new Date()).toISOString(),
                platform: 'Website Landing Page',
                readstatus: false,
                createdDtm: new Date(),
                id: leadid
            })
            .then(alert('Call Request Sent Successfully,\n We will contact you as soon as possible. Thank You'))
            .then(setNameText(''), setNumberText(''), setMessageText(''), setcontactText(''))



    }
    return (

        <View style={styles.Container}>

            <View style={styles.textContainer}>
                <TextInput
                    adjustsFontSizeToFit={true}
                    allowFontScaling={true}
                    value={nameText}
                    placeholder={onFocus ? '' : 'Name*'}
                    onChangeText={(editedText) => { setNameText(editedText) }}
                    onFocus={() => { setOnFocus(true), setOnBlur(false) }}
                    onBlur={() => { setOnBlur(true), setOnFocus(false) }}
                    onKeyPress={(nativeEvent) => {
                        if (nativeEvent.key === 'Backspace') {
                            //setSubHeaderTextHeight(0) 
                        }
                    }}
                    placeholderTextColor={colours.greyFive}
                    style={styles.TextInput}
                />
                <TextInput
                    adjustsFontSizeToFit={true}
                    allowFontScaling={true}
                    value={numberText}
                    placeholder={onFocusNumber ? numberText : 'Mobile No*'}
                    keyboardType='numeric'
                    maxLength={17}

                    onChangeText={(editedText) => { setNumberText(editedText) }}
                    onFocus={() => { setOnFocusNumber(true), setOnBlurNumber(false) }}
                    onBlur={() => { setOnBlurNumber(true), setOnFocusNumber(false) }}
                    onKeyPress={(nativeEvent) => {
                        if (nativeEvent.key === 'Backspace') {
                            //setSubHeaderTextHeight(0) 
                        }
                    }}
                    placeholderTextColor={colours.greyFive}

                    style={styles.TextInput}

                />

                <TextInput
                    onChangeText={(editedText) => { setcontactText(editedText) }}
                    value={loading ? '' : contactText}
                    placeholder={'Email (optional)'}
                    placeholderTextColor={colours.greyFive}
                    style={styles.TextInput}

                />


                <TouchableOpacity
                    onPress={() => {
                        isNaN(numberText) ? alert('Please Provide Contact Number') :
                            nameText.length < 2 ? alert('Please Provide Name') :
                                numberText.length < 5 ? alert('Please Provide Contact Number') :
                                    contactUsData()
                        // contactText.length < 3 ? alert('Please Let us know your Email') : contactUsData()

                    }
                    }
                >
                    <View style={styles.submitButton}>
                        <Text style={styles.textSubmit}>Send</Text>
                    </View>
                </TouchableOpacity>
            </View>



        </View>

    )
}

export default LeadFormFill;

const styles = StyleSheet.create({

    Container: {
        // width: width > 1000 ? 400 : width - 20,
        marginTop: 10,
        paddingBottom: 10,
        // borderWidth:1,
        // borderColor:colours.primary,
        // borderRadius:10,
        // margin:10,

        // backgroundColor: colours.blueGreyLight,
        // shadowColor: colours.black,
        // shadowOffset: { width: 0, height: 2, },
        // shadowOpacity: 0.1,
        // shadowRadius: 20,
        // elevation: 3,
    },

    textContainer: {
        paddingTop: 20,
        marginHorizontal: 10,
        alignItems: 'center',
    },
    TextInput: {
        width: '90%',
        textAlign: 'left',
        fontFamily: fonts.primary,
        marginBottom: 20,
        lineHeight:40,
        marginTop: 2,
        color: colours.greyDark,
        fontSize: '.9rem',
        fontWeight: '500',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10,
        borderColor: colours.greyFour,

    },

    textSubmit: {
        paddingHorizontal: 25,
        fontSize: 20,
        color: colours.white,
        fontFamily: fonts.primary

    },

    submitButton: {
        backgroundColor: colours.primaryDarker,
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
        marginTop: 10,
        borderRadius: 5,

    },

});