import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import { StyleSheet, View, ScrollView, Image, Text, Animated, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation } from '@react-navigation/native';
import fonts from '../../config/fonts';

import WhatsApp from '../../assets/images/social/WhatsappIcon.svg';
import Call from '../../assets/images/social/call.svg';
import Mail from '../../assets/images/social/mail.svg';
import LeadFormFill from '../promotions/leadFormFill';



const { height, width } = Dimensions.get('window')

const About = ({ route }) => {


    const [dimensions, setDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth })
    const handleResize = () => { setDimensions({ width: window.innerWidth, height: window.innerHeight, }); }
    const { width, height } = dimensions;

    useEffect(() => {
        window.scrollTo(0, 0),
            window.addEventListener("resize", handleResize, false)
        return () => window.removeEventListener("resize", handleResize)

        //firebase.analytics();
        // eslint-disable-next-line
    }, [width, height]);

    const [pageNumber, setPageNumber] = useState(0)
    const offset = useRef(new Animated.Value(0)).current;

    const navigation = useNavigation();



    const styles = StyleSheet.create({
        containerScrollView: {
            flex: 1,
            marginTop: width > 999 ? pageNumber > 0 ? 60 : 78 : pageNumber > 0 ? 40 : 65
        },
        containerRight: {
            flex: 2,
            marginVertical: 10,
            paddingVertical: 10,
        },

        containerInsideHeaderRight: {
            borderBottomWidth: 1,
            marginBottom:7,
            borderColor: colours.primaryLighter,
            marginHorizontal: width > 1000 ? 16 : 10,
           // alignSelf:'flex-start'
           // backgroundColor: colours.blueGreyLight,
        },
        textHeader: {
            paddingHorizontal: 10,
            paddingVertical:5,
            color: colours.secondary,
            fontSize: 20,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'justify',
        },


     
        textSubmit: {
            paddingHorizontal: 20,
            alignContent: 'center',
            justifyContent: 'center',
            paddingTop: 10,
            fontSize: 20,
            color: colours.white,
            fontFamily: fonts.primary

        },


        containerBody: {
            width: width > 1080 ? 1280 : width,
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignSelf: 'center',
        },

        containerBodyMobile: {
            flex: 1,
            alignItems: 'center'
        },
        containerBodyPrimary: {
            width: width,
            alignSelf: 'center',
            backgroundColor: colours.blueGreyLight

        },
        containerBodySecondary: {
            width: width,
            alignSelf: 'center',
            backgroundColor: colours.white
        },

        containerDetailsRow: {
            width: width > 1275 ? 1280 : width,
            alignSelf: 'center',
            flexDirection: width > 1000 ? 'row' : 'column',
        },

        containerDetails: {
            flex: 2,
            marginVertical: 15,
            marginHorizontal: width > 1275 ? 15 : 10,
            alignSelf: 'flex-start',
            backgroundColor: colours.white,
            borderRadius: 5,
        },
        containerDetailsMobile: {
            marginVertical: 15,
            marginHorizontal: 15,
            alignSelf:width > 1275 ?'flex-start':'stretch',
            backgroundColor: colours.white,
            borderRadius: 5,
        },

        containerForm: {
            flex: width > 1280 ? 1 : 1,
            marginVertical: 15,
            marginHorizontal: 15,
            backgroundColor: colours.white,
            borderRadius: 5,
            alignSelf:width > 1275 ?'flex-start':'stretch'

        },



        containerCard: {
            borderRadius: 5,
            borderBottomWidth: 2,
            //borderRightWidth: 3,
            borderColor: colours.primary,
            backgroundColor: colours.white,
            width: width > 1275 ? 300 : 200,
            height: width > 1275 ? 340 : 250,
            marginHorizontal: 55,
            marginBottom: 25,

            shadowColor: colours.black,
            shadowOffset: { width: 0, height: 3, },
            shadowOpacity: 0.2,
            shadowRadius: 20,
            elevation: 5,
        },

        containerCardImage: {
            overflow: 'hidden',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            height: width > 1275 ? 250 : 160,
        },
        containerBulletPoints: {
            flexDirection: 'column'
        },
        containerHeaderRow: {
            // width: '100%',
            flexDirection: 'row',
            //justifyContent: 'space-between',
            alignSelf: 'flex-start'
        },
        textH1: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: width > 1275 ? 20 : 20,
            paddingBottom: 5,
            color: colours.secondary,
            fontSize: width > 1275 ? 30 : 22,
            fontWeight: width > 1275 ? '500' : '600',
            fontFamily: fonts.primary,
            textAlign: 'left',

        },
        textH2: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: 20,
            paddingBottom: 5,
            color: colours.secondary,
            fontSize: width > 1275 ? 25 : 17,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },
        textParagraph: {
            paddingHorizontal: 20,
            paddingBottom: 15,
            color: colours.black,
            fontWeight: '300',
            fontSize: width > 1275 ? 16 : 14,
            textAlign: 'left',
            fontFamily: fonts.primary,
            lineHeight: width > 1275 ? 30 : 24,
        },
        textParagraphBold: {
            paddingHorizontal: 20,
            paddingBottom: 15,
            color: colours.black,
            fontWeight: '500',
            fontSize: width > 1275 ? 16 : 14,
            textAlign: 'left',
            fontFamily: fonts.primary,
            lineHeight: width > 1275 ? 30 : 24,
        },


        submitButton: {
            width: '90%',
            flexDirection: 'row',
            backgroundColor: '#25D366',
            alignSelf: 'center',
            justifyContent: 'center',
            paddingHorizontal: 20,
            paddingVertical: 5,
            borderRadius: 5,

        },

    });





    return (
        <View style={{ height: height, backgroundColor: colours.white }}>
            {/* HEADER SECTION */}
            <Header animatedValue={offset} pageNumber={pageNumber} />

            <ScrollView
                style={styles.containerScrollView}
                contentContainerStyle={{ flexGrow: 1 }}
                //pagingEnabled={true}
                showsVerticalScrollIndicator={false}
                scrollEventThrottle={16}
                onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: offset } } }], { useNativeDriver: false, listener: event => { const offsetY = event.nativeEvent.contentOffset.y; setPageNumber(offsetY / height) } })}
            >
                {/* BANNER SECTION */}
                <View style={styles.containerBodySecondary}>
                    <View style={{ marginVertical: width > 1275 ? 20 : 5 }} />
                    <View style={width > 999 ? styles.containerBody : styles.containerBodyMobile}>

                        <View style={styles.containerHeaderRow}>
                            <Text style={[styles.textH1, { color: colours.primary, fontSize: width > 1275 ? 50 : 25 }]}>
                                {'About Us'}
                            </Text>
                        </View>
                    </View>


                </View>

                <View style={styles.containerBodyPrimary}>


                    <View style={styles.containerDetailsRow}>

                        <View style={width > 1275 ? styles.containerDetails : styles.containerDetailsMobile}>


                            <View style={{ marginVertical: width > 1275 ? 20 : 5 }} />

                            {/*HIGHLIGHTS*/}
                            <View style={styles.containerBulletPoints}>
                                <Text style={[styles.textH2,{lineHeight:40,color:colours.primaryDark}]}>
                                
                                    {'"We started from a dream. Based on the idea to be pioneers in the industry, with a vision of dynamic marketing strategies, coupled with exceptional customer service, Sokna Real Estate opened its doors as a two man operation."'}

                                </Text>

                                <View style={{ marginVertical: 20, paddingLeft: 20 }}>

                                    <View style={styles.containerRight}>
                                        <View style={styles.containerInsideHeaderRight}>
                                            <Text style={styles.textHeader}>{'WHO WE ARE'}</Text>
                                        </View>

                                        <Text style={[styles.textParagraphBold, { color: colours.black }]}>
                                            {'At Sokna Real Estate  , we are your one-stop-shop for all things real estate. With a resolution to always offer 5-star service to our clients, we are here for you and all your property requirements and queries. At the crux of our business is that we work relentlessly to provide hassle-free tailored real estate advice and consultancy for investors and families alike who are looking to find the perfect home.\nWe take pride in our diverse portfolio, of not just services but also the Sokna Real Estate   team members. We have over 22 different nationalities speaking 25+ different languages, all ready to answer your property-related questions.\nEstablished in 2008, today, we not only help you buy and lease a property but also manage your property, help you find the right financing options or get you the perfect holiday home. We pride ourselves in being transparent, honest and professional to deliver the best results for our clients.\n '}
                                        </Text>

                                    </View>

                                    <View style={styles.containerRight}>
                                        <View style={styles.containerInsideHeaderRight}>
                                            <Text style={styles.textHeader}>{'MISSION'}</Text>
                                        </View>

                                        <Text style={[styles.textParagraphBold, { color: colours.black }]}>
                                        {'To Create Honest, Trustworthy, & Transparent relations with Home Buyers, Tenants &Investors. We strive to achieve perfection in the digital age with the best tools in the industry and providing an environment for employees to thrive in.'}
                                        </Text>

                                    </View>


                                    <View style={styles.containerRight}>
                                        <View style={styles.containerInsideHeaderRight}>
                                            <Text style={styles.textHeader}>{'VISION'}</Text>
                                        </View>

                                        <Text style={[styles.textParagraphBold, { color: colours.black }]}>
                                        {'We aim to be at the forefront of the real estate industry from service standards and customer service to technological solutions. We want to continue to provide the best tailored services at every step.'}
                                        </Text>

                                    </View>

                                    <View style={styles.containerRight}>
                                        <View style={styles.containerInsideHeaderRight}>
                                            <Text style={styles.textHeader}>{'OUR VALUES'}</Text>
                                        </View>

                                        <Text style={[styles.textParagraphBold, { color: colours.black }]}>
                                        {'At Sokna Real Estate  , we aren’t just a team, we are a close-knit family. We work hard and play hard. We celebrate every team member, every milestone, and every achievement. Offering a friendly and fun work-environment, Sokna Real Estate   in-vests in each member of the team in terms of time and training sessions. If you are looking for the next growth period in your career, Sokna Real Estate   Estate is your calling. Will you answer?'}
                                        </Text>

                                    </View>


                                </View>
                            </View>

     


                            <View style={{ marginVertical: 10 }} />


                        </View>




                        

                        <View style={styles.containerForm}>


                            <Text style={styles.textH2}>
                                {'Connect With Us'}
                            </Text>
                            <Text style={[styles.textParagraph, { color: colours.secondaryDarker }]}>
                                {'Request A Callback'}
                            </Text>
                            <LeadFormFill pageName={'About'} />

                            <View style={{ marginVertical: 10, borderBottomColor:colours.blueGreyLight, borderBottomWidth:15}} />

                            <Text style={styles.textH2}>
                                {'Chat With Us'}
                            </Text>
                            <Text style={[styles.textParagraph, { color: colours.secondaryDarker }]}>
                                {'Start Converstation in WhatsApp'}
                            </Text>



                            <TouchableOpacity style={styles.submitButton} onPress={() => Linking.openURL('https://api.whatsapp.com/send?phone=971568225126')} >
                                <View> <Image source={WhatsApp} style={{ width: 40, height: 40 }} /></View>
                                <View><Text style={styles.textSubmit}>{'Start Chat'}</Text>  </View>
                            </TouchableOpacity>

                            <View style={{ marginVertical: 20 }} />

                        </View>
                    </View>
                </View>














                {/* FOOTER SECTION */}
                <Footer animatedValue={offset} pageNumber={pageNumber} />
            </ScrollView>
        </View>

    );
};




export default About;




