

import "typeface-lato"
import "typeface-montserrat"
import "typeface-open-sans"

const fonts = {
  primary	: 'montserrat',

  };

  export default fonts;
  