import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import { ActivityIndicator, ImageBackground, FlatList, StyleSheet, Animated, ScrollView, View, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking, ImageBackgroundBase } from 'react-native';


import colours from '../../config/colours';
import fonts from '../../config/fonts'

import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation } from '@react-navigation/native';

import HomeBanner from '../../components/homeBanner';
import MapIcon from '../../assets/images/icons/mapw.svg'

import iconVilla from '../../assets/images/villa.png'
import iconTH from '../../assets/images/townhouse.png'
import iconAPT from '../../assets/images/apartment.png'

import { AuthContext } from '../../providers/dataProvider'

const { height, width } = Dimensions.get('window')

import 'firebase/analytics'
import Floating from '../../components/floating';

const Home = () => {


  const [dimensions, setDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth })
  const handleResize = () => { setDimensions({ width: window.innerWidth, height: window.innerHeight, }); }

  let CurrentSlide = 0;
  const flatList = useRef()

  //banner things
  const [sliderNo, setSliderNo] = useState(0);

  const goToNextPage = () => {
    if (CurrentSlide >= (featuredProjects.length - 3))
      CurrentSlide = -1;
    flatList.current.scrollToIndex({
      index: ++CurrentSlide,
      animated: true,
    });
    // console.log('currentslide',index, CurrentSlide,featuredProjects.length)
    // setSliderNo(CurrentSlide)
  };
  //banner things ends


  const { width, height } = dimensions;

  useEffect(() => {
    // let timerId = setInterval(goToNextPage, intervalTime);
    // return () => clearInterval(timerId),
    window.scrollTo(0, 0),
      window.addEventListener("resize", handleResize, false)
    return () => window.removeEventListener("resize", handleResize)

    //firebase.analytics();
    // eslint-disable-next-line
  }, [width, height]);

  const navigation = useNavigation();
  const [pageNumber, setPageNumber] = useState(0)
  const offset = useRef(new Animated.Value(0)).current;
  const { featuredProjects,projectsAbuDhabi,projectsDubai,projectsRecent,isLoading } = useContext(AuthContext)



  const Loading = () => {
    return (
        <View>
            <ActivityIndicator size='large' color={colours.secondaryLight} />
        </View>
    )
}


  const renderFeatured = (item, index) => {
    const gradientHeight = 70;
    const gradientBackground = colours.black;
    const data = Array.from({ length: gradientHeight });


    const images = item.images[0].split(',')
    const imageThumb = images[1]
    const imageMain = images[0]
    return (
      <TouchableOpacity onPress={() => navigation.navigate('Property', { propertyid: item })}>

        <View style={styles.containerCard}>
          <View style={styles.containerCardImage}>
            <ImageBackground source={imageMain} style={{ height: '100%' }} />

            <View style={{ flex: 1, marginBottom: 15 }}>
              {data.map((_, i) => (
                <View
                  key={i}
                  style={{
                    position: 'absolute',
                    backgroundColor: gradientBackground,
                    height: 1,
                    bottom: (gradientHeight - i),
                    right: 0,
                    left: 0,
                    elevation: 2,
                    zIndex: 2,
                    opacity: (1 / gradientHeight) * (i + 1)
                  }}
                />
              ))}
            </View>
            <View style={styles.containerOverlay}>
              <Image source={MapIcon} style={{ width: 15, height: 20, resizeMode: 'contain' }} />

              <Text style={styles.textNew2}>{' ' + item.city}</Text>
            </View>
          </View>
          {item.sticker != null && item.sticker != undefined && item.sticker != '' &&
            <View style={styles.containerNew}>
              <Text style={styles.textNew}>{item.sticker}</Text>
            </View>}


          <Text numberOfLines={1} style={styles.textCardTitle}>
            {item.name}
          </Text>

          {/* <Text style={styles.textCardSubtitle}>{'Starting from ' + item.priceStart}</Text> */}
          <Text style={styles.textCardSubtitle}>{item.priceStart}</Text>

        </View>
      </TouchableOpacity>
    )
  }


  const renderProjectsDubai = (item, index) => {
    const gradientHeight = 70;
    const gradientBackground = colours.black;
    const data = Array.from({ length: gradientHeight });

    const images = item.images[0].split(',')
    const imageThumb = images[1]
    const imageMain = images[0]
    return (
      <TouchableOpacity onPress={() => navigation.navigate('Property', { propertyid: item })}>

        <View style={styles.containerCard}>
          <View style={styles.containerCardImage}>
            <ImageBackground source={imageThumb} style={{ height: '100%' }} />

            <View style={{ flex: 1, marginBottom: 15 }}>
              {data.map((_, i) => ( <View key={i}  style={{ position: 'absolute',  backgroundColor: gradientBackground,  height: 1,  bottom: (gradientHeight - i),  right: 0,  left: 0, elevation: 2, zIndex: 2, opacity: (1 / gradientHeight) * (i + 1)   }} />   ))}
            </View>
            <View style={styles.containerOverlay}>
              <Image source={MapIcon} style={{ width: 15, height: 20, resizeMode: 'contain' }} />

              <Text style={styles.textNew2}>{' ' + item.city}</Text>
            </View>
          </View>
          {item.sticker != null && item.sticker != undefined && item.sticker != '' &&
            <View style={styles.containerNew}>
              <Text style={styles.textNew}>{item.sticker}</Text>
            </View>}

          <Text numberOfLines={1} style={styles.textCardTitle}>
            {item.name}
          </Text>
          <Text style={styles.textCardSubtitle}>{item.priceStart}</Text>

        </View>
      </TouchableOpacity>
    )
  }

  const renderProjectsAbuDhabi = (item, index) => {
    const gradientHeight = 70;
    const gradientBackground = colours.black;
    const data = Array.from({ length: gradientHeight });

    const images = item.images[0].split(',')
    const imageThumb = images[1]
    const imageMain = images[0]
    return (
      <TouchableOpacity onPress={() => navigation.navigate('Property', { propertyid: item })}>

        <View style={styles.containerCard}>
          <View style={styles.containerCardImage}>
            <ImageBackground source={imageThumb} style={{ height: '100%' }} />

            <View style={{ flex: 1, marginBottom: 15 }}>
              {data.map((_, i) => ( <View key={i}  style={{ position: 'absolute',  backgroundColor: gradientBackground,  height: 1,  bottom: (gradientHeight - i),  right: 0,  left: 0, elevation: 2, zIndex: 2, opacity: (1 / gradientHeight) * (i + 1)   }} />   ))}
            </View>
            <View style={styles.containerOverlay}>
              <Image source={MapIcon} style={{ width: 15, height: 20, resizeMode: 'contain' }} />

              <Text style={styles.textNew2}>{' ' + item.city}</Text>
            </View>
          </View>
          {item.sticker != null && item.sticker != undefined && item.sticker != '' &&
            <View style={styles.containerNew}>
              <Text style={styles.textNew}>{item.sticker}</Text>
            </View>}

          <Text numberOfLines={1} style={styles.textCardTitle}>
            {item.name}
          </Text>
          <Text style={styles.textCardSubtitle}>{item.priceStart}</Text>

        </View>
      </TouchableOpacity>
    )
  }



  const renderProjectsRecent = (item, index) => {
    const gradientHeight = 70;
    const gradientBackground = colours.black;
    const data = Array.from({ length: gradientHeight });

    const images = item.images[0].split(',')
    const imageThumb = images[1]
    const imageMain = images[0]
    return (
      <TouchableOpacity onPress={() => navigation.navigate('Property', { propertyid: item })}>

        <View style={styles.containerCard}>
          <View style={styles.containerCardImage}>
            <ImageBackground source={imageThumb} style={{ height: '100%' }} />

            <View style={{ flex: 1, marginBottom: 15 }}>
              {data.map((_, i) => ( <View key={i}  style={{ position: 'absolute',  backgroundColor: gradientBackground,  height: 1,  bottom: (gradientHeight - i),  right: 0,  left: 0, elevation: 2, zIndex: 2, opacity: (1 / gradientHeight) * (i + 1)   }} />   ))}
            </View>
            <View style={styles.containerOverlay}>
              <Image source={MapIcon} style={{ width: 15, height: 20, resizeMode: 'contain' }} />

              <Text style={styles.textNew2}>{' ' + item.city}</Text>
            </View>
          </View>
          {item.sticker != null && item.sticker != undefined && item.sticker != '' &&
            <View style={styles.containerNew}>
              <Text style={styles.textNew}>{item.sticker}</Text>
            </View>}

          <Text numberOfLines={1} style={styles.textCardTitle}>
            {item.name}
          </Text>
          <Text style={styles.textCardSubtitle}>{item.priceStart}</Text>

        </View>
      </TouchableOpacity>
    )
  }



  // STYLE SECTION
  const styles = StyleSheet.create({

    container: {
      flexGrow: 1,
    },
    containerBody: {
      width: width > 1080 ? 1280 : width,
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignSelf: 'center',
    },

    containerBodyMobile: {
      flex: 1,
      alignItems: 'center'
    },
    containerBodyPrimary: {
      width: width,
      alignSelf: 'center',
      backgroundColor: colours.blueGreyLight
    },
    containerBodySecondary: {
      width: width,
      alignSelf: 'center',
      backgroundColor: colours.white
    },

    flatlistcontainer: {
      width: width > 1080 ? 1280 : width,
      // marginHorizontal: 10,
      paddingVertical: 25,
      paddingHorizontal: 20
    },
    containerNew: {
      //backgroundColor: '#5533a2',
      backgroundColor: colours.primary,
      // backgroundColor: colours.primary ,
      alignSelf: 'center',
      paddingHorizontal: 10,
      paddingVertical: 5,
      borderRadius: 15,
      position: 'absolute',
      top: 15,
      right: -25,
    },
    containerOverlay: {
      flexDirection: 'row',
      alignSelf: 'left',
      paddingHorizontal: 10,
      paddingVertical: 5,
      position: 'absolute',
      bottom: 0,
      right: 0,
      //right: -45,
    },
    textNew: {
      color: colours.white,
      fontSize: width > 1275 ? 15 : 12,
      fontFamily: fonts.primary,
      fontWeight: '600'
    },
    textNew2: {
      color: colours.white,
      fontSize: width > 1275 ? 14 : 11,
      fontFamily: fonts.primary,
      fontWeight: '500',
    },

    containerCard: {
      borderRadius: 5,
      borderBottomWidth: 2,
      //borderRightWidth: 3,
      borderColor: colours.primary,
      backgroundColor: colours.white,
      width: width > 1275 ? 300 : 200,
      height: width > 1275 ? 340 : 250,
      marginHorizontal: width > 1275 ? 55 : 20,
      marginBottom: 25,

      shadowColor: colours.black,
      shadowOffset: { width: 0, height: 3, },
      shadowOpacity: 0.2,
      shadowRadius: 20,
      elevation: 5,
    },

    containerCardImage: {
      overflow: 'hidden',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      height: width > 1275 ? 250 : 160,
    },
    textCardHeading: {
      paddingTop: width > 1275 ? 25 : 20,
      paddingHorizontal: 20,
      paddingBottom: 5,
      color: colours.primaryDark,
      fontSize: width > 1275 ? 30 : 22,
      fontWeight: '500',
      fontFamily: fonts.primary,
      textAlign: 'left',
    },

    textCardTitle: {
      paddingTop: 15,
      paddingHorizontal: 20,
      paddingBottom: 5,
      color: colours.primaryDark,
      fontSize: width > 1275 ? 18 : 15,
      fontWeight: '600',
      fontFamily: fonts.primary,
      textAlign: 'left',
    },
    textCardSubtitle: {
      paddingHorizontal: 20,
      paddingBottom: 15,
      color: colours.black,
      fontWeight: '300',
      fontSize: width > 1275 ? 13 : 9,
      textAlign: 'left',
      fontFamily: fonts.primary,

    },
    textHovered: {
      borderBottomWidth: 2,
      borderBottomColor: colours.primary,
      marginBottom: -2,
    },
    //CATEGORY//

    containerFlatlistCategory: {
      width: width > 1080 ? 1280 : width,
      paddingVertical: 30,
      paddingHorizontal: 20
    },

    containerCardCategory: {
      borderColor: colours.brand,
      width: width > 1275 ? 370 : 200,
      marginHorizontal: 25,
    },

    containerCardImageCategory: {
      height: width > 1275 ? 150 : 100,
      width: width > 1275 ? 150 : 100,
      alignSelf: 'center'

    },
    textCardHeadingCategory: {
      paddingTop: width > 1275 ? 45 : 35,
      paddingHorizontal: 20,
      color: colours.secondary,
      fontSize: width > 1275 ? 30 : 26,
      fontWeight: '500',
      fontFamily: fonts.primary,
      textAlign: 'center',

    },

    textCardTitleCategory: {
      paddingTop: 5,
      paddingHorizontal: 20,
      paddingBottom: 5,
      color: colours.secondaryDarkest,
      fontSize: width > 1275 ? 17 : 14,
      fontWeight: '500',
      fontFamily: fonts.primary,
      textAlign: 'center',
    },
    textCardSubtitleCategory: {
      paddingTop: 5,
      paddingHorizontal: 20,
      paddingBottom: 15,
      color: colours.black,
      fontWeight: '300',
      fontSize: width > 1275 ? 14 : 10,
      textAlign: 'left',
      fontFamily: fonts.primary,

    },
    textHoveredCategory: {
      borderBottomWidth: 2,
      borderBottomColor: colours.brand,
      marginBottom: -2,
    },
    containerHeaderRow: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }
  });

  {/* MAIN SECTION*/ }

  return (
    <View style={{ height: height, backgroundColor: colours.white }}>
      {/* HEADER SECTION */}
      <Header animatedValue={offset} pageNumber={pageNumber} />
      <Floating animatedValue={offset} pageNumber={pageNumber} />

      <ScrollView
        style={{ flex: 1, marginTop: width > 999 ? pageNumber > 0 ? 60 : 78 : pageNumber > 0 ? 40 : 65 }}
        contentContainerStyle={{ flexGrow: 1 }}
        //pagingEnabled={true}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: offset } } }],
          {
            useNativeDriver: false,
            listener: event => {
              const offsetY = event.nativeEvent.contentOffset.y
              setPageNumber(offsetY / height)
            }
          }
        )}
      >
        {/* BANNER SECTION */}

        <HomeBanner />


        {/* BODY SECTION */}

        {/* FEATURED SECTION*/}


        <View style={styles.containerBodyPrimary}>
          <View style={{ marginVertical: 20 }} />
          <View style={width > 999 ? styles.containerBody : styles.containerBodyMobile}>


            <View style={styles.containerHeaderRow}>
              <Text style={styles.textCardHeading}>{'FEATURED PROPERTIES'}</Text>
              <TouchableOpacity
                onPress={() => navigation.navigate('Properties Category', { category: 'Featured' })}
              //onPress={() => goToNextPage()}
              >
                <Text style={[styles.textCardHeading, { fontSize: 50, lineHeight: 20, textAlign: 'right' }]}>{'\u27F6'}</Text></TouchableOpacity>
            </View>
            {isLoading && <Loading />}
            <FlatList
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              data={featuredProjects}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item, index }) => renderFeatured(item, index)}
              initialNumToRender={12}
              ref={flatList}
              contentContainerStyle={styles.flatlistcontainer}
            />



          </View>
        </View>




        {/* CATEGORIES SECTION*/}

        <View style={styles.containerBodySecondary}>
          <Text style={[styles.textCardHeading, { textAlign: 'center' }]}>{'Select Property Type'}</Text>

          <View style={width > 999 ? styles.containerBody : styles.containerBodyMobile}>

            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: width > 999 ? 50 : 0 }}>

              <TouchableOpacity onPress={() => navigation.navigate('Properties Category', { category: 'Apartments' })} >
                <Image source={iconAPT} style={{ height: 100, resizeMode: 'contain' }} />
                <Text numberOfLines={1} style={styles.textCardTitleCategory}>
                  {'Apartments'}
                </Text>
              </TouchableOpacity>


              <TouchableOpacity onPress={() => navigation.navigate('Properties Category', { category: 'Townhouses' })} >
                <Image source={iconTH} style={{ height: 100, resizeMode: 'contain' }} />
                <Text numberOfLines={1} style={styles.textCardTitleCategory}>
                  {'Townhouses'}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => navigation.navigate('Properties Category', { category: 'Villas' })} >
                <Image source={iconVilla} style={{ height: 100, resizeMode: 'contain' }} />
                <Text numberOfLines={1} style={styles.textCardTitleCategory}>
                  {'Villas'}
                </Text>
              </TouchableOpacity>

            </View>

          </View>
          <View style={{ marginVertical: 20 }} />

        </View>




        {/* DUBAI SECTION*/}


        <View style={styles.containerBodyPrimary}>
          <View style={{ marginVertical: 20 }} />
          <View style={width > 999 ? styles.containerBody : styles.containerBodyMobile}>


            <View style={styles.containerHeaderRow}>
              <Text style={styles.textCardHeading}>{'Properties In Dubai'}</Text>
              <TouchableOpacity
                onPress={() => navigation.navigate('Properties Category', { category: 'Dubai' })}
              //onPress={() => goToNextPage()}
              >
                <Text style={[styles.textCardHeading, { fontSize: 50, lineHeight: 20, textAlign: 'right' }]}>{'\u27F6'}</Text></TouchableOpacity>
            </View>
            {isLoading && <Loading />}
            <FlatList
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              data={projectsDubai}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item, index }) => renderProjectsDubai(item, index)}
              initialNumToRender={12}
              ref={flatList}
              contentContainerStyle={styles.flatlistcontainer}
            />



          </View>
        </View>








        {/* ABU DHABI SECTION*/}


        <View style={[styles.containerBodyPrimary,{backgroundColor:colours.white}]}>
          <View style={{ marginVertical: 20 }} />
          <View style={width > 999 ? styles.containerBody : styles.containerBodyMobile}>


            <View style={styles.containerHeaderRow}>
              <Text style={styles.textCardHeading}>{'Properties In Abu Dhabi'}</Text>
              <TouchableOpacity
                onPress={() => navigation.navigate('Properties Category', { category: 'Abu Dhabi' })}
              //onPress={() => goToNextPage()}
              >
                <Text style={[styles.textCardHeading, { fontSize: 50, lineHeight: 20, textAlign: 'right' }]}>{'\u27F6'}</Text></TouchableOpacity>
            </View>
            {isLoading && <Loading />}
            <FlatList
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              data={projectsAbuDhabi}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item, index }) => renderProjectsAbuDhabi(item, index)}
              initialNumToRender={12}
              ref={flatList}
              contentContainerStyle={styles.flatlistcontainer}
            />



          </View>
        </View>






        {/* RECENT SECTION*/}


        <View style={styles.containerBodyPrimary}>
          <View style={{ marginVertical: 20 }} />
          <View style={width > 999 ? styles.containerBody : styles.containerBodyMobile}>


            <View style={styles.containerHeaderRow}>
              <Text style={styles.textCardHeading}>{'Recently Added'}</Text>
              <TouchableOpacity
                onPress={() => navigation.navigate('Properties Category', { category: 'All' })}
              >
                <Text style={[styles.textCardHeading, { fontSize: 50, lineHeight: 20, textAlign: 'right' }]}>{'\u27F6'}</Text></TouchableOpacity>
            </View>
            {isLoading && <Loading />}
            <FlatList
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              data={projectsRecent}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item, index }) => renderProjectsRecent(item, index)}
              initialNumToRender={12}
              ref={flatList}
              contentContainerStyle={styles.flatlistcontainer}
            />



          </View>
        </View>















        {/* FOOTER SECTION */}
        <Footer animatedValue={offset} pageNumber={pageNumber} />
      </ScrollView>
    </View>


  );
};



export default Home;
