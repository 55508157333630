import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import { Pressable, ImageBackground, FlatList, StyleSheet, Animated, ScrollView, View, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';


import colours from '../../config/colours';
import fonts from '../../config/fonts'

import Header from '../../components/header'
import Footer from '../../components/footer'

import MapIcon from '../../assets/images/icons/map.svg'
import DriveIcon from '../../assets/images/icons/car.svg'
import DownloadIcon from '../../assets/images/icons/download.svg'
import WhatsApp from '../../assets/images/social/WhatsappIcon.svg';

import Modal from 'react-native-modal';

const { height, width } = Dimensions.get('window')

import 'firebase/analytics'
import LeadFormFill from '../promotions/leadFormFill';
import Floating from '../../components/floating';

const Property = ({ route }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalData, setModalData] = useState();


    let imagesdb = (route.params.propertyid.images)
    let galleryDB = imagesdb.map(d => ({ imgURL: d.split(',')[0], thumbURL: d.split(',')[1] }))

    const [dimensions, setDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth })
    const handleResize = () => { setDimensions({ width: window.innerWidth, height: window.innerHeight, }); }
    const { width, height } = dimensions;



    useEffect(() => {
        window.scrollTo(0, 0),
            window.addEventListener("resize", handleResize, false)
        return () => window.removeEventListener("resize", handleResize)

        //firebase.analytics();
        // eslint-disable-next-line
    }, [width, height]);





    //banner things
    let CurrentSlide = 0;
    const flatList = useRef()
    const galleryThumbs = useRef()

    const [sliderNo, setSliderNo] = useState(0);

    const goToNextPage = ({ num }) => {
        if (num >= (galleryDB.length - 1))
            num = -1;
        flatList.current.scrollToIndex({
            index: ++num,
            animated: true,
        });


        galleryThumbs.current?.scrollTo({ x: width > 1275 ? 100 * num : 70 * num, animated: true });

        setSliderNo(num)

        // setSliderNo(sliderNo+1)
        //console.log(CurrentSlide, sliderNo)

    };


    const changeSlide = ({ num }) => {
        console.log(num)

        //CurrentSlide = num ;
        flatList.current.scrollToIndex({
            index: num,
            animated: true,
        });
        galleryThumbs.current?.scrollTo({ x: width > 1275 ? 100 * num : 70 * num, animated: true });

        setSliderNo(num)

    };


    //banner things ends




    const [pageNumber, setPageNumber] = useState(0)
    const offset = useRef(new Animated.Value(0)).current;
    const [pageName, setPageName] = useState(route.params.propertyid.name)



    const renderList = (item) => {
        const gradientHeight = 170;
        const gradientBackground = colours.secondaryDarkest;
        const data = Array.from({ length: gradientHeight });
        return (

            <View >
                <TouchableOpacity
                    onPress={() => { setIsModalVisible(true); setModalData(item.imgURL); }}
                >                <Image
                        source={{ uri: item.imgURL }}
                        style={styles.containerGallery}
                    />
                </TouchableOpacity>
            </View>
        )
    }

    // STYLE SECTION
    const styles = StyleSheet.create({

        containerScrollView: {
            flex: 1,
            marginTop: width > 999 ? pageNumber > 0 ? 60 : 78 : pageNumber > 0 ? 40 : 65
        },
        containerGallery: {
            marginHorizontal: 10,
            width: width > 1275 ? 800 : width - 40,
            height: width > 1275 ? 400 : 200,
            resizeMode: 'contain',
            alignContent: 'center'
        },
        containerGalleryThumb: {
            width: width > 1275 ? 100 : 70,
            height: width > 1275 ? 80 : 50,
            resizeMode: 'cover',
            marginHorizontal: 3,
            borderColor: colours.primaryLight
        },
        textCardHeading: {
            paddingHorizontal: width > 1275 ? 20 : 10,
            color: colours.secondary,
            fontSize: width > 1275 ? 50 : 40,
            fontWeight: '800',
            fontFamily: fonts.primary,
        },
        containerFlatlist: {
            width: width > 1080 ? 1250 : width - 30,
            paddingVertical: 10,
        },
        docContainer: {
            flexDirection: 'row',
            marginStart: 20,
            // alignSelf: 'center',
        },
        docContainerMobile: {
            width: width * .85,
            flexDirection: 'row',
            marginStart: 20,
            // alignSelf: 'center',
        },

        textDot: {
            fontSize: 25,
            fontWeight: '600'
        },

        dotInActive: {
            flexDirection: 'row',
            width: 5,
            height: 2,
            // borderRadius:2.5,
            paddingHorizontal: 15,
            marginHorizontal: 5,
            backgroundColor: colours.primaryDarkest,
            alignSelf: 'center'
        },
        dotActive: {
            flexDirection: 'row',
            paddingHorizontal: 15,
            paddingVertical: 3,
            marginHorizontal: 5,
            //  borderRadius:50,
            backgroundColor: colours.primaryDarker,
            alignSelf: 'center'
        },

        containerBody: {
            width: width > 1080 ? 1280 : width,
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignSelf: 'center',
        },

        containerBodyMobile: {
            flex: 1,
            alignItems: 'center'
        },
        containerBodyPrimary: {
            width: width,
            alignSelf: 'center',
            backgroundColor: colours.blueGreyLight

        },
        containerBodySecondary: {
            width: width,
            alignSelf: 'center',
            backgroundColor: colours.white
        },

        containerDetailsRow: {
            width: width > 1275 ? 1280 : width,
            alignSelf: 'center',
            flexDirection: width > 1000 ? 'row' : 'column',
        },

        containerDetails: {
            flex: 2,
            marginVertical: 15,
            marginHorizontal: width > 1275 ? 15 : 10,
            alignSelf: 'flex-start',
            backgroundColor: colours.white,
            borderRadius: 5,
        },
        containerDetailsMobile: {
            marginVertical: 15,
            marginHorizontal: 15,
            alignSelf: width > 1275 ? 'flex-start' : 'stretch',
            backgroundColor: colours.white,
            borderRadius: 5,
        },

        containerForm: {
            flex: width > 1280 ? 1 : 1,
            marginVertical: 15,
            marginHorizontal: 15,
            backgroundColor: colours.white,
            borderRadius: 5,
            alignSelf: width > 1275 ? 'flex-start' : 'stretch',
        },
        containerSummaryCardRow: {
            paddingVertical: 15,
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around'
        },
        containerSummaryCard: {
            alignSelf: 'center',
            marginVertical: width > 1280 ? 0 : 10,
            flexDirection: 'column',
            justifyContent: 'center',
            borderRadius: 5,
            backgroundColor: colours.greyLight,
            borderColor: colours.primary,
            borderWidth: 1,
        },
        containerCardAmenitiesRow: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        containerCardAmenities: {
            // flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            borderRadius: 5,
            margin: 10,
            paddingTop: 20,
            paddingBottom: 10,
            paddingHorizontal: 10,
            //backgroundColor: colours.greyLight,
            borderColor: colours.secondaryLightest,
            borderWidth: 1,
        },
        containerCardConnectivity: {
            flexWrap: 'wrap',
            justifyContent: 'center',
            borderRadius: 5,
            margin: 10,
            paddingTop: 20,
            paddingBottom: 10,
            paddingHorizontal: 10,
            backgroundColor: colours.secondary
        },
        flatlistcontainer: {
            width: width > 1080 ? 1280 : width,
            // marginHorizontal: 10,
            paddingVertical: 25,
            paddingHorizontal: 20
        },
        containerBulletPoints: {
            width: width > 1080 ? 1280 : width,
            flexDirection: 'column',
        },
        containerNew: {
            //backgroundColor: '#5533a2',
            backgroundColor: colours.primary,
            // backgroundColor: colours.primary ,
            alignSelf: 'center',
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: 15,
            position: 'absolute',
            top: 30,
            //left: -45,
        },
        containerOverlay: {
            alignSelf: 'left',
            paddingHorizontal: 10,
            paddingVertical: 5,
            position: 'absolute',
            bottom: 0,
            right: 0,
            //right: -45,
        },
        textSticker: {
            backgroundColor: colours.primary,
            color: colours.white,
            fontSize: width > 1275 ? 15 : 10,
            fontFamily: fonts.primary,
            fontWeight: '600',
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: 15,
            textAlignVertical: 'top'
            //position: 'absolute',
            // top: 30,
            // right:-90
        },
        textNew: {
            color: colours.white,
            fontSize: width > 1275 ? 15 : 12,
            fontFamily: fonts.primary,
            fontWeight: '600'
        },
        textNew2: {
            color: colours.white,
            fontSize: width > 1275 ? 14 : 11,
            fontFamily: fonts.primary,
            fontWeight: '500',
        },

        containerCard: {
            borderRadius: 5,
            borderBottomWidth: 2,
            //borderRightWidth: 3,
            borderColor: colours.primary,
            backgroundColor: colours.white,
            width: width > 1275 ? 300 : 200,
            height: width > 1275 ? 340 : 250,
            marginHorizontal: 55,
            marginBottom: 25,

            shadowColor: colours.black,
            shadowOffset: { width: 0, height: 3, },
            shadowOpacity: 0.2,
            shadowRadius: 20,
            elevation: 5,
        },

        containerCardImage: {
            overflow: 'hidden',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            height: width > 1275 ? 250 : 160,
        },
        containerBulletPoints: {
            flexDirection: 'column'
        },
        containerHeaderRow: {
            // width: '100%',
            flexDirection: 'row',
            //justifyContent: 'space-between',
            alignSelf: 'flex-start'
        },
        textH1: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: width > 1275 ? 20 : 20,
            paddingBottom: 5,
            color: colours.secondary,
            fontSize: width > 1275 ? 30 : 22,
            fontWeight: width > 1275 ? '500' : '600',
            fontFamily: fonts.primary,
            textAlign: 'left',

        },
        textH2: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: 20,
            paddingBottom: 5,
            color: colours.primaryDark,
            fontSize: width > 1275 ? 25 : 17,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },

        textH3: {
            paddingTop: 15,
            paddingHorizontal: 20,
            paddingBottom: 5,
            color: colours.primaryDark,
            fontSize: width > 1275 ? 18 : 15,
            fontWeight: '600',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },
        textH4: {
            paddingHorizontal: 20,
            paddingBottom: 15,
            color: colours.black,
            fontWeight: '300',
            fontSize: width > 1275 ? 13 : 9,
            textAlign: 'left',
            fontFamily: fonts.primary,

        },
        textParagraph: {
            paddingHorizontal: 20,
            paddingBottom: 15,
            color: colours.black,
            fontWeight: '300',
            fontSize: width > 1275 ? 16 : 14,
            textAlign: 'left',
            fontFamily: fonts.primary,
            lineHeight: width > 1275 ? 30 : 24,
        },
        textParagraphBold: {
            paddingHorizontal: 20,
            paddingBottom: 15,
            color: colours.black,
            fontWeight: '500',
            fontSize: width > 1275 ? 16 : 14,
            textAlign: 'left',
            fontFamily: fonts.primary,
            //lineHeight: width > 1275 ? 26 : 24,
        },

        textSubmit: {
            paddingHorizontal: 20,
            alignContent: 'center',
            justifyContent: 'center',
            paddingTop: 10,
            fontSize: 20,
            color: colours.white,
            fontFamily: fonts.primary

        },

        submitButton: {
            width: '90%',
            flexDirection: 'row',
            backgroundColor: '#25D366',
            alignSelf: 'center',
            justifyContent: 'center',
            paddingHorizontal: 20,
            paddingVertical: 5,
            borderRadius: 5,

        },

        fullsizeimage: {
            width: width,
            height: height - height * .1,
            resizeMode: 'contain',
            alignSelf: 'center',
        },

        closeContainer: {
            backgroundColor: 'darkred',
            alignItems: 'center',
            borderRadius: 5,
            paddingHorizontal: 20,
            paddingVertical: 5,
        },
        closeButtonContainer: {
            flex: 1,
            alignItems: 'center',
            color: 'red',
            marginBottom: 20,

        },
        closeText: {
            fontSize: 30,
            fontWeight: '700',
            color: colours.white,
        },
    });

    {/* MAIN SECTION*/ }

    return (
        <View style={{ height: height, backgroundColor: colours.white }}>
            {/* HEADER SECTION */}
            <Header animatedValue={offset} pageNumber={pageNumber} />
            <Floating animatedValue={offset} pageNumber={pageNumber} />

            <ScrollView
                style={styles.containerScrollView}
                contentContainerStyle={{ flexGrow: 1 }}
                //pagingEnabled={true}
                showsVerticalScrollIndicator={false}
                scrollEventThrottle={16}
                onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: offset } } }], { useNativeDriver: false, listener: event => { const offsetY = event.nativeEvent.contentOffset.y; setPageNumber(offsetY / height) } })}
            >
                {/* BANNER SECTION */}


                {/* BODY SECTION */}

                {/* GALLERY */}



                {/* TITLE*/}

                <View style={styles.containerBodySecondary}>
                    <View style={{ marginVertical: width > 1275 ? 20 : 5 }} />
                    <View style={width > 999 ? styles.containerBody : styles.containerBodyMobile}>

                        <View style={styles.containerHeaderRow}>
                            <Text style={[styles.textH1, { color: colours.primary, fontSize: width > 1275 ? 50 : 25 }]}>{route.params.propertyid.name}
                                {route.params.propertyid.sticker != null && route.params.propertyid.sticker != undefined && route.params.propertyid.sticker != '' &&
                                    <Text style={styles.textSticker}>{route.params.propertyid.sticker}</Text>
                                }</Text>
                        </View>
                    </View>

                    <View style={[styles.containerDetailsRow, { marginVertical: 0}]}>
                        <Text style={styles.textParagraph}>
                            <Image source={MapIcon} style={{marginHorizontal:10, width: 20, height: 20, resizeMode: 'contain' }} />
                            {route.params.propertyid.community}{', '}
                            {route.params.propertyid.city}{', '}
                            {route.params.propertyid.country}
                        </Text>
                    </View>

                </View>



                <View style={styles.containerBodyPrimary}>


                    <View style={styles.containerDetailsRow}>

                        <View style={width > 1275 ? styles.containerDetails : styles.containerDetailsMobile}>


                            <FlatList
                                horizontal={true}
                                showsHorizontalScrollIndicator={false}
                                data={galleryDB}
                                keyExtractor={(item, index) => index.toString()}
                                renderItem={({ item }) => renderList(item)}
                                initialNumToRender={30}
                                contentContainerStyle={styles.containerFlatlist}
                                ref={flatList}
                            />

                            <View style={width > 1275 ? styles.docContainer : styles.docContainerMobile}>


                                <ScrollView ref={galleryThumbs} horizontal={true} showsHorizontalScrollIndicator={false}>

                                    {galleryDB.map((item, index) => (
                                        <View
                                            key={index}
                                        >
                                            <TouchableOpacity onPress={() => changeSlide({ num: index })}>

                                                <Image
                                                    source={{ uri: item.thumbURL }}
                                                    style={[styles.containerGalleryThumb, {
                                                        borderWidth: index === sliderNo ? 7 : 0,
                                                    }]}
                                                />
                                            </TouchableOpacity>
                                        </View>

                                    ))}
                                </ScrollView>

                                <TouchableOpacity onPress={() => goToNextPage({ num: sliderNo })}>
                                    <Text style={styles.textCardHeading}>
                                        {'\u27F6'}
                                    </Text>
                                </TouchableOpacity>

                            </View>








                            <View style={{ marginVertical: width > 1275 ? 20 : 5 }} />

                            <View style={styles.containerSummaryCardRow}>
                                {/*PRICE*/}
                                <View style={styles.containerSummaryCard}>
                                    <Text style={styles.textH3}>
                                        {'Starting Price'}
                                    </Text>
                                    <Text style={styles.textParagraphBold}>
                                        {route.params.propertyid.priceStart}
                                    </Text>
                                </View>

                                {/*DEVELOPER*/}
                                <View style={styles.containerSummaryCard}>
                                    <Text style={styles.textH3}>
                                        {'Developer'}
                                    </Text>
                                    <Text style={styles.textParagraphBold}>
                                        {route.params.propertyid.developer}
                                    </Text>
                                </View>

                                {/*Summary*/}
                                <View style={[styles.containerSummaryCard, { paddingTop: 10 }]}>

                                    {(route.params.propertyid.summary).map((item, index) => (
                                        <View style={{ flexDirection: 'row' }}>

                                            <Text index={index} style={styles.textParagraphBold}>
                                                {item}
                                            </Text>
                                        </View>
                                    ))}




                                </View>

                            </View>







                            {/*HIGHLIGHTS*/}
                            {route.params.propertyid.highlights!=null &&
                            
                            <View style={styles.containerBulletPoints}>
                            
                                <Text style={styles.textH2}>
                                    {'Highlights'}
                                </Text>

                                <View style={{ marginVertical: 20, paddingLeft: 20 }}>

                                    {(route.params.propertyid.highlights).map((item, index) => (
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text index={index} style={styles.textParagraphBold}>
                                                {'\u25cf'}
                                            </Text>
                                            <Text index={index} style={styles.textParagraphBold}>
                                                {item}
                                            </Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
}


                            {/*DESCRIPTIOSN*/}
                            <Text style={styles.textH2}>
                                {'Description'}
                            </Text>
                            <Text style={styles.textParagraph}>
                                {route.params.propertyid.description}
                            </Text>

                            {/*AMENITIES*/}
                            {route.params.propertyid.amenities != null &&
                                <View>
                                    <Text style={styles.textH2}>
                                        {'Features & Amenities'}
                                    </Text>

                                    <View style={styles.containerCardAmenitiesRow}>
                                        {(route.params.propertyid.amenities).map((item, index) => (
                                            <View style={styles.containerCardAmenities}>
                                                <Text index={index} style={[styles.textParagraphBold, { fontWeight: '600', color: colours.primaryDark }]}>
                                                    {item}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>
                                </View>
                            }

                            {/*CONNECTIVITY*/}
                            {route.params.propertyid.connectivity != null &&
                                <View>
                                    <Text style={styles.textH2}>
                                        {'Connectivity'}
                                    </Text>

                                    <View style={styles.containerCardAmenitiesRow}>

                                        {(route.params.propertyid.connectivity).map((items, index) => (

                                            <View style={styles.containerCardConnectivity}>
                                                <Text index={index} style={[styles.textParagraphBold, { fontWeight: '600', color: colours.white, textAlign: 'center', paddingBottom: 0 }]}>
                                                    {(items.split(','))[0]}
                                                </Text>
                                                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Image source={DriveIcon} style={{ width: 25, height: 20 }} />
                                                    <Text index={index} style={[styles.textParagraph, { fontWeight: '400', color: colours.blueGreyLight, paddingTop: 15, fontSize: 14, paddingHorizontal: 0 }]}>

                                                        {(items.split(','))[1]}
                                                    </Text>
                                                </View>


                                            </View>
                                        ))}
                                    </View>
                                </View>
                            }




                            {/*LOCATION MAP*/}
                            <Text style={styles.textH2}>
                                {'Location Map'}
                            </Text>
                            <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center' }} onPress={() => Linking.openURL(route.params.propertyid.locationMap)} >
                                <Text style={[styles.textParagraphBold, { color: colours.white, backgroundColor: colours.primaryDarker, alignSelf: 'flex-start', marginStart: 20, paddingTop: 10, borderRadius: 5, }]}>
                                    {'Click Here'} </Text>
                                <Text style={[styles.textParagraphBold, { color: colours.secondaryDark, paddingTop: 10, paddingLeft: 5 }]}>
                                    {'to View in Google Maps '}

                                </Text>
                            </TouchableOpacity>



                            {/*DOWNLOADS*/}
                            <View style={{ marginVertical: 20 }} />
                            <Text style={styles.textH2}>
                                {'Downloads'}
                            </Text>
                            <Text style={[styles.textParagraphBold, { color: colours.secondaryDark }]}>
                                {'Get more information from PDFs'}
                            </Text>

                            <View style={styles.containerCardAmenitiesRow}>

                                {(route.params.propertyid.pdfs).map((items, index) => (

                                    <View style={[styles.containerCardConnectivity, { backgroundColor: colours.primaryDark }]}>
                                        <Text index={index} style={[styles.textParagraphBold, { fontWeight: '500', color: colours.white, textAlign: 'center', paddingBottom: 10, fontSize: 14 }]}>
                                            <Image source={DownloadIcon} style={{ width: 25, height: 20 }} /> {(items.split(','))[0]}
                                        </Text>



                                    </View>
                                ))}
                            </View>


                            <View style={{ marginVertical: 20 }} />


                        </View>

                        <View style={styles.containerForm}>


                            <Text style={styles.textH2}>
                                {'Connect With Us'}
                            </Text>
                            <Text style={[styles.textParagraph, { color: colours.secondaryDark }]}>
                                {'Request A Callback'}
                            </Text>
                            <LeadFormFill pageName={pageName} />

                            <View style={{ marginVertical: 10, borderBottomColor: colours.blueGreyLight, borderBottomWidth: 15 }} />

                            <Text style={styles.textH2}>
                                {'Chat With Us'}
                            </Text>
                            <Text style={[styles.textParagraph, { color: colours.secondaryDark }]}>
                                {'Start Converstation in WhatsApp'}
                            </Text>



                            <TouchableOpacity style={styles.submitButton} onPress={() => Linking.openURL('https://api.whatsapp.com/send?phone=971568225126')} >
                                <View> <Image source={WhatsApp} style={{ width: 40, height: 40 }} /></View>
                                <View><Text style={styles.textSubmit}>{'Start Chat'}</Text>  </View>
                            </TouchableOpacity>

                            <View style={{ marginVertical: 20 }} />

                        </View>


                    </View>



                </View>

                {/* FOOTER SECTION */}
                <Footer animatedValue={offset} pageNumber={pageNumber} />
            </ScrollView>




            <Modal
                isVisible={isModalVisible}
                transparent={true}
                onBackdropPress={() => setIsModalVisible(false)}
                animationIn={'zoomInDown'}
                animationOut={'zoomOutDown'}
                style={styles.modalContainer}
            >

                <View>
                    <Image
                        style={styles.fullsizeimage}
                        source={{ uri: modalData }}
                    />
                </View>
                <View style={styles.closeButtonContainer} >
                    <TouchableOpacity
                        onPress={() => setIsModalVisible(false)}
                    >
                        <View style={styles.closeContainer}>
                            <Text style={styles.closeText}>X</Text>
                        </View>

                    </TouchableOpacity>
                </View>

            </Modal>

        </View>


    );
};



export default Property;
