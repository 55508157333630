import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import { useNavigation } from '@react-navigation/native';

import WhatsApp from '../../assets/images/social/Whatsapp.svg';
import Email from '../../assets/images/social/Email.svg';
import Call from '../../assets/images/social/phone.svg';


const { height, width } = Dimensions.get('window')



const LeadFormConnect = () => {

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  const {width, height} = dimensions;

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize);

    //firebase.analytics();
    // eslint-disable-next-line
  }, [width, height]);




    const navigation = useNavigation();
    const iconWidth=55;
    return (

        <View style={styles.container}>


            <View style={styles.containerRow}>

<View style={styles.containerHeader}>
    <Text numberOfLines={1} style={styles.textSubHeader} >
        Lets Start {'    '}
    </Text>
</View>

<TouchableOpacity onPress={() => Linking.openURL('https://api.whatsapp.com/send?phone=971503773805')}  >
<View style={styles.containerIcon}> <img src={WhatsApp} width={iconWidth} /> </View>
    </TouchableOpacity>

    <TouchableOpacity onPress={() => Linking.openURL('tel:+971503773805')} >
    <View style={styles.containerIcon}> <img src={Call} width={iconWidth} /></View>
    </TouchableOpacity>

    <TouchableOpacity onPress={() => Linking.openURL('mailto:info@pdcconsult.com?subject=Villa Construction Ad Enquiry')}>
    <View style={styles.containerIcon}>  <img src={Email} width={iconWidth} /></View>
    </TouchableOpacity>

</View>



        </View>

    );
};



const styles = StyleSheet.create({
    container: {
        width: width > 1000 ? 400 : width - 20,
        marginTop: 10,
        paddingBottom: 10,

        backgroundColor: colours.blueGreyLight,

        shadowColor: colours.black,
        shadowOffset: { width: 0, height: 2, },
        shadowOpacity: 0.1,
        shadowRadius: 20,
        elevation: 3,
    },
    containerRow: {
        paddingHorizontal: 10,
        paddingVertical: 10,
        flexDirection: 'row',
    },
    containerIcon: {
        marginHorizontal: width > 1000 ? 10 : 4,
    },

    containerHeader: {
        marginTop: 5,
        paddingLeft: 15
    },

    textHeader: {
        paddingLeft: 10,
        fontSize: 18,
        color: colours.greyDark,
        fontWeight: '500',
        lineHeight: 27,
    },
    textSubHeader: {
        paddingLeft: 10,
        fontSize: 16,
        color: colours.greyDark,
        fontWeight: '300',
        lineHeight: 27,
    },




});


export default LeadFormConnect;