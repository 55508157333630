import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import { Pressable, ImageBackground, FlatList, StyleSheet, Animated, ScrollView, View, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking, ActivityIndicator } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation } from '@react-navigation/native';
import fonts from '../../config/fonts';

import WhatsApp from '../../assets/images/social/WhatsappIcon.svg';
import LeadFormFill from '../promotions/leadFormFill';
import { AuthContext } from '../../providers/dataProvider'
import Floating from '../../components/floating';
import MapIcon from '../../assets/images/icons/mapw.svg'
import { firebase } from '../../config/firebase'



const { height, width } = Dimensions.get('window')

const Properties = ({ route }) => {


    const [dimensions, setDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth })
    const handleResize = () => { setDimensions({ width: window.innerWidth, height: window.innerHeight, }); }
    const { width, height } = dimensions;

    const navigation = useNavigation();
    const [isLoading, setIsLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);

    const [lastDoc, setLastDoc] = useState(null);

    const { featuredProjects } = useContext(AuthContext)
    const [projects, setProjects] = useState([]);


    useEffect(() => {
        window.scrollTo(0, 0), getProjects()
        window.addEventListener("resize", handleResize, false)
        return () => window.removeEventListener("resize", handleResize)

        //firebase.analytics();
        // eslint-disable-next-line
    }, [width, height]);

    const [pageNumber, setPageNumber] = useState(0)
    const offset = useRef(new Animated.Value(0)).current;




    const flatList = useRef()

    const getProjects = async () => {

        setIsLoading(true);
        const snapshot = await firebase.firestore().collection('propertiesdb')
            //.where('tags', 'array-contains', 'Featured')
            .orderBy('id', 'desc')
            .limit(width > 1275 ? 3 : 2)
            .get()

        if (!snapshot.empty) {
            setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
            let d = snapshot.docs.map((doc) => ({ docid: doc.id, ...doc.data() }))
            setProjects(d);
            setIsLoading(false);
        }
    }

    const getProjectsMore = async () => {

        if (lastDoc) {

            setIsMoreLoading(true);

            setTimeout(async () => {

                const snapshot = await firebase.firestore().collection('propertiesdb')
                    //.where('tags', 'array-contains', 'Featured')
                    .orderBy('id', 'desc')
                    .startAfter(lastDoc)
                    .limit(width > 1275 ? 3 : 2)
                    .get()

                if (!snapshot.empty) {
                    let newProp = projects;

                    setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
                    for (let i = 0; i < snapshot.docs.length; i++) {
                        let newData = snapshot.docs[i].data()
                        let docidnew = snapshot.docs[i].id
                        newProp.push(Object.assign(newData, { docid: docidnew }));
                    }
                    setProjects(newProp);
                } else { setLastDoc(null) }


                setIsMoreLoading(false);


            }, 1000);

        }


    }




    const Loading = () => {
        return (
            <View>
                <ActivityIndicator size='large' color={colours.secondaryLight} />
            </View>
        )
    }



    const styles = StyleSheet.create({

        containerNew: {
            //backgroundColor: '#5533a2',
            //backgroundColor: '#c724b1',
            backgroundColor: colours.primaryDark,
            alignSelf: 'center',
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: 15,
            position: 'absolute',
            top: 10,
            right: -25,
        },
        containerOverlay: {
            flexDirection: 'row',
            alignSelf: 'left',
            paddingHorizontal: 10,
            paddingVertical: 5,
            position: 'absolute',
            bottom: 0,
            right: 0,
            //right: -45,
        },
        textNew: {
            color: colours.white,
            fontSize: width > 1275 ? 15 : 12,
            fontFamily: fonts.primary,
            fontWeight: '600'
        },
        textNew2: {
            color: colours.white,
            fontSize: width > 1275 ? 14 : 11,
            fontFamily: fonts.primary,
            fontWeight: '500',
        },

        containerCard: {
            borderRadius: 5,
            borderBottomWidth: 2,
            //borderRightWidth: 3,
            borderColor: colours.primary,
            backgroundColor: colours.white,
            width: width > 1275 ? 220 : 150,
            height: width > 1275 ? 280 : 190,
            marginHorizontal: 20,
            marginVertical:7,

            shadowColor: colours.black,
            shadowOffset: { width: 0, height: 3, },
            shadowOpacity: 0.2,
            shadowRadius: 5,
            elevation: 5,
        },

        containerCardImage: {
            overflow: 'hidden',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            height: width > 1275 ? 180 : 120,
        },
        textCardHeading: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: 20,
            paddingBottom: 5,
            color: colours.primaryDark,
            fontSize: width > 1275 ? 30 : 22,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },

        textCardTitle: {
            paddingTop: 15,
            paddingHorizontal: 20,
            paddingBottom: 5,
            color: colours.primaryDark,
            fontSize: width > 1275 ? 18 : 15,
            fontWeight: '600',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },
        textCardSubtitle: {
            paddingHorizontal: 20,
            paddingBottom: 15,
            color: colours.black,
            fontWeight: '300',
            fontSize: width > 1275 ? 13 : 9,
            textAlign: 'left',
            fontFamily: fonts.primary,

        },
        textHovered: {
            borderBottomWidth: 2,
            borderBottomColor: colours.brand,
            marginBottom: -2,
        },
        containerScrollView: {
            flex: 1,
            marginTop: width > 999 ? pageNumber > 0 ? 60 : 78 : pageNumber > 0 ? 40 : 65
        },
        containerRight: {
            //flex: 1,
            marginVertical: 10,
            paddingVertical: 10,
        },

        containerInsideHeaderRight: {
            borderBottomWidth: 1,
            marginBottom: 7,
            borderColor: colours.primaryLight,
            marginHorizontal: width > 1000 ? 16 : 10,
            // alignSelf:'flex-start'
            // backgroundColor: colours.blueGreyLight,
        },
        textHeader: {
            paddingHorizontal: 10,
            paddingVertical: 5,
            color: colours.secondary,
            fontSize: 20,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'justify',
        },



        textSubmit: {
            paddingHorizontal: 20,
            alignContent: 'center',
            justifyContent: 'center',
            paddingTop: 10,
            fontSize: 20,
            color: colours.white,
            fontFamily: fonts.primary

        },


        containerBody: {
            width: width > 1080 ? 1280 : width,
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignSelf: 'center',
        },

        containerBodyMobile: {
            flex: 1,
            alignItems: 'center'
        },
        containerBodyPrimary: {
            width: width,
            alignSelf: 'center',
            backgroundColor: colours.blueGreyLight

        },
        containerBodySecondary: {
            width: width,
            alignSelf: 'center',
            backgroundColor: colours.white
        },

        containerDetailsRow: {
            width: width > 1275 ? 1280 : width,
            alignSelf: 'center',
            flexDirection: width > 1000 ? 'row' : 'column',
        },

        containerDetails: {
            flex: 2,
            marginVertical: 15,
            marginHorizontal: width > 1275 ? 15 : 10,
            alignSelf: 'flex-start',
            backgroundColor: colours.white,
            borderRadius: 5,
        },
        containerDetailsMobile: {
            //flex: 2,
            marginVertical: 15,
            marginHorizontal: 10,
            backgroundColor: colours.white,
            borderRadius: 5,
        },
        flatlistcontainer: {
            flexWrap: 'wrap',
            alignContent:'center'

        },
        containerForm: {
            flex: width > 1280 ? 1 : 1,
            marginVertical: 15,
            marginHorizontal: 15,
            backgroundColor: colours.white,
            borderRadius: 5,
            alignSelf: width > 1275 ? 'flex-start' : 'stretch'
        },




        containerBulletPoints: {
            flexDirection: 'column'
        },
        containerHeaderRow: {
            flexDirection: 'row',
        },
        textH1: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: width > 1275 ? 20 : 20,
            paddingBottom: 5,
            color: colours.secondary,
            fontSize: width > 1275 ? 30 : 22,
            fontWeight: width > 1275 ? '500' : '600',
            fontFamily: fonts.primary,
            textAlign: 'left',

        },
        textH2: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: 20,
            paddingBottom: 5,
            color: colours.primaryDark,
            fontSize: width > 1275 ? 25 : 17,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },
        textH3: {
            marginVertical:0,
           // paddingTop: width > 1275 ? 20 : 10,
            paddingHorizontal: 20,
            paddingBottom: 5,
            color: colours.white,
            fontSize: width > 1275 ? 18 : 13,
            fontWeight: '500',
            fontFamily: fonts.primary,
        },
        textParagraph: {
            paddingHorizontal: 20,
            paddingBottom: 15,
            color: colours.black,
            fontWeight: '300',
            fontSize: width > 1275 ? 16 : 14,
            textAlign: 'left',
            fontFamily: fonts.primary,
            lineHeight: width > 1275 ? 30 : 24,
        },
        textParagraphBold: {
            paddingHorizontal: 20,
            paddingBottom: 15,
            color: colours.black,
            fontWeight: '500',
            fontSize: width > 1275 ? 16 : 14,
            textAlign: 'left',
            fontFamily: fonts.primary,
            lineHeight: width > 1275 ? 30 : 24,
        },
        loadMoreButton: {
            marginVertical:10,
            //width: '90%',
            flexDirection: 'row',
            backgroundColor: colours.primaryDarker,
            alignSelf: 'center',
            justifyContent: 'center',
            paddingHorizontal: 20,
            paddingVertical: 5,
            borderRadius: 5,
        },

        submitButton: {
            width: '90%',
            flexDirection: 'row',
            backgroundColor: '#25D366',
            alignSelf: 'center',
            justifyContent: 'center',
            paddingHorizontal: 20,
            paddingVertical: 5,
            borderRadius: 5,
        },

    });



    const renderFeatured = (item, index) => {
        const gradientHeight = 70;
        const gradientBackground = colours.black;
        const data = Array.from({ length: gradientHeight });


        const images = item.images[0].split(',')
        const imageThumb = images[1]
        const imageMain = images[0]
        return (
            <View style={styles.containerCard}>
                <View style={styles.containerCardImage}>
                    <ImageBackground source={imageMain} style={{ height: '100%' }} />

                    <View style={{ flex: 1, marginBottom: 15 }}>
                        {data.map((_, i) => (
                            <View
                                key={i}
                                style={{
                                    position: 'absolute',
                                    backgroundColor: gradientBackground,
                                    height: 1,
                                    bottom: (gradientHeight - i),
                                    right: 0,
                                    left: 0,
                                    elevation: 2,
                                    zIndex: 2,
                                    opacity: (1 / gradientHeight) * (i + 1)
                                }}
                            />
                        ))}
                    </View>
                    <View style={styles.containerOverlay}>
                        <Image source={MapIcon} style={{ width: 15, height: 20, resizeMode: 'contain' }} />

                        <Text style={styles.textNew2}>{' ' + item.city}</Text>
                    </View>
                </View>
                {item.sticker != null && item.sticker != undefined && item.sticker != '' &&
                    <View style={styles.containerNew}>
                        <Text style={styles.textNew}>{item.sticker}</Text>
                    </View>}

                <TouchableOpacity onPress={() => navigation.navigate('Property', { propertyid: item })} >
                    <Text numberOfLines={1} style={styles.textCardTitle}>
                        {/* <Pressable onPress={() => navigation.navigate('Property', { propertyid: item })} style={(state) => state.hovered && styles.textHovered} > */}
                        {item.name}
                        {/* </Pressable> */}
                    </Text>
                </TouchableOpacity>

                {/* <Text style={styles.textCardSubtitle}>{'Starting from ' + item.priceStart}</Text> */}
                <Text style={styles.textCardSubtitle}>{item.priceStart}</Text>

            </View>
        )
    }




    const renderProperties = (item, index) => {
        const gradientHeight = 70;
        const gradientBackground = colours.black;
        const data = Array.from({ length: gradientHeight });


        const images = item.images[0].split(',')
        const imageThumb = images[1]
        const imageMain = images[0]
        return (
            <View style={styles.containerCard}>
                <View style={styles.containerCardImage}>
                    <ImageBackground source={imageMain} style={{ height: '100%' }} />

                    <View style={{ flex: 1, marginBottom: 15 }}>
                        {data.map((_, i) => (
                            <View
                                key={i}
                                style={{
                                    position: 'absolute',
                                    backgroundColor: gradientBackground,
                                    height: 1,
                                    bottom: (gradientHeight - i),
                                    right: 0,
                                    left: 0,
                                    elevation: 2,
                                    zIndex: 2,
                                    opacity: (1 / gradientHeight) * (i + 1)
                                }}
                            />
                        ))}
                    </View>
                    <View style={styles.containerOverlay}>
                        <Image source={MapIcon} style={{ width: 15, height: 20, resizeMode: 'contain' }} />

                        <Text style={styles.textNew2}>{' ' + item.city}</Text>
                    </View>
                </View>
                {item.sticker != null && item.sticker != undefined && item.sticker != '' &&
                    <View style={styles.containerNew}>
                        <Text style={styles.textNew}>{item.sticker}</Text>
                    </View>}

                <TouchableOpacity onPress={() => navigation.navigate('Property', { propertyid: item })}>
                    <Text numberOfLines={1} style={styles.textCardTitle}>
                        {/* <Pressable onPress={() => navigation.navigate('Property', { propertyid: item })} style={(state) => state.hovered && styles.textHovered} > */}
                        {item.name}
                        {/* </Pressable> */}
                    </Text>
                </TouchableOpacity>

                {/* <Text style={styles.textCardSubtitle}>{'Starting from ' + item.priceStart}</Text> */}
                <Text style={styles.textCardSubtitle}>{item.priceStart}</Text>

            </View>
        )
    }




    return (
        <View style={{ height: height, backgroundColor: colours.white }}>
            {/* HEADER SECTION */}
            <Header animatedValue={offset} pageNumber={pageNumber} />
            <Floating animatedValue={offset} pageNumber={pageNumber} />

            <ScrollView
                style={styles.containerScrollView}
                contentContainerStyle={{ flexGrow: 1 }}
                //pagingEnabled={true}
                showsVerticalScrollIndicator={false}
                scrollEventThrottle={16}
                onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: offset } } }], { useNativeDriver: false, listener: event => { const offsetY = event.nativeEvent.contentOffset.y; setPageNumber(offsetY / height) } })}
            >
                {/* BANNER SECTION */}
                <View style={styles.containerBodySecondary}>
                    <View style={{ marginVertical: width > 1275 ? 20 : 5 }} />
                    <View style={width > 999 ? styles.containerBody : styles.containerBodyMobile}>

                        <View style={styles.containerHeaderRow}>
                            <Text style={[styles.textH1, { color: colours.primary, fontSize: width > 1275 ? 50 : 25, }]}>
                                {'PROPERTIES'}
                            </Text>
                        </View>
                    </View>


                </View>

                <View style={styles.containerBodyPrimary}>


                    <View style={styles.containerDetailsRow}>

                        <View style={width > 1275 ? styles.containerDetails : styles.containerDetailsMobile}>


                            <View style={{ marginVertical: width > 1275 ? 20 : 5 }} />

                            {/*HIGHLIGHTS*/}
                                <Text style={styles.textH2}>
                                    {'BEST DEALS'}
                                </Text>

                                <View style={{ marginVertical: 20, paddingLeft: width > 1275 ? 20 : 0 }}>

                                    <View style={styles.containerInsideHeaderRight}>
                                        <Text style={styles.textHeader}>{'OFF PLAN PROPERTIES'}</Text>
                                    </View>

                                    <Text style={[styles.textParagraphBold, { color: colours.black }]}>
                                        {'Purchasing off-plan property is becoming increasingly popular amongst investors – and individuals, for that matter (such is the lack of housing stock here in the UAE).\n\nThis means increased competition for many developments that come to market. The advantage property investors have though is their access to quick cash and the ability to buy more than one property. Both of which also means securing a good discount.\n\nFor investors buying off-plan property can lead to high returns and capital growth. '}
                                    </Text>

                                </View>
                                

                                {/* FEATURED*/}
                                <View style={styles.containerHeaderRow}>
                                    <Text style={styles.textH1}>{'Recently Added '}</Text>
                                    {/* <TouchableOpacity onPress={() => goToNextPage()}><Text style={[styles.textCardHeading, { fontSize: 50, lineHeight: 20, textAlign: 'right' }]}>{'\u27F6'}</Text></TouchableOpacity> */}
                                </View>

                                {width > 1275 && 
                                    <FlatList
                                        numColumns={3}
                                        showsHorizontalScrollIndicator={false}
                                        data={projects}
                                        keyExtractor={(item, index) => index.toString()}
                                        renderItem={({ item, index }) => renderProperties(item, index)}
                                        initialNumToRender={12}
                                        ref={flatList}
                                        contentContainerStyle={styles.flatlistcontainer}
                                    />}

                                    {width < 1275 &&
                                    <FlatList
                                        numColumns={2}
                                        showsHorizontalScrollIndicator={false}
                                        data={projects}
                                        keyExtractor={(item, index) => index.toString()}
                                        renderItem={({ item, index }) => renderProperties(item, index)}
                                        initialNumToRender={12}
                                        ref={flatList}
                                        contentContainerStyle={styles.flatlistcontainer}
                                    />
                                    }
                                    {!isMoreLoading && lastDoc &&
                                        <View >
                                            <TouchableOpacity 
                                            style={styles.loadMoreButton}
                                            onPress={() => getProjectsMore()}>
                                                <Text style={styles.textH3}>{'More..'}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    }{isMoreLoading &&
                                        <Loading />
                                    }






<View style={{ borderBottomColor:colours.secondaryLightest,borderBottomWidth:1,marginHorizontal:20,marginBottom:10 }}/>





                                {/* FEATURED*/}
                                <View style={styles.containerHeaderRow}>
                                    <Text style={styles.textH1}>{'Popular Properties'}</Text>
                
                                </View>

                                <FlatList
                                    horizontal={true}
                                    showsHorizontalScrollIndicator={false}
                                    data={featuredProjects}
                                    keyExtractor={(item, index) => index.toString()}
                                    renderItem={({ item, index }) => renderFeatured(item, index)}
                                    initialNumToRender={12}
                                    ref={flatList}

                                    contentContainerStyle={styles.flatlistcontainer}
                                />


                            <View style={{ marginVertical: 10 }} />


                        </View>






                        <View style={styles.containerForm}>


                            <Text style={styles.textH2}>
                                {'Connect With Us'}
                            </Text>
                            <Text style={[styles.textParagraph, { color: colours.secondaryDarker }]}>
                                {'Request A Callback'}
                            </Text>
                            <LeadFormFill pageName={'About'} />

                            <View style={{ marginVertical: 10, borderBottomColor: colours.blueGreyLight, borderBottomWidth: 15 }} />

                            <Text style={styles.textH2}>
                                {'Chat With Us'}
                            </Text>
                            <Text style={[styles.textParagraph, { color: colours.secondaryDarker }]}>
                                {'Start Converstation in WhatsApp'}
                            </Text>



                            <TouchableOpacity style={styles.submitButton} onPress={() => Linking.openURL('https://wa.me/971568225126')} >
                                <View> <Image source={WhatsApp} style={{ width: 40, height: 40 }} /></View>
                                <View><Text style={styles.textSubmit}>{'Start Chat'}</Text>  </View>
                            </TouchableOpacity>

                            <View style={{ marginVertical: 20 }} />

                        </View>
                    </View>
                </View>














                {/* FOOTER SECTION */}
                <Footer animatedValue={offset} pageNumber={pageNumber} />
            </ScrollView>
        </View>

    );
};




export default Properties;




