import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView, Linking } from 'react-native';

import SoknaLogo from '../assets/images/logo/sokna2.svg'
import colours from '../config/colours';
import fonts from '../config/fonts'
import FooterWidgets from './footerWidgets';
import Facebook from '../assets/images/social/Facebook.svg';
import WhatsApp from '../assets/images/social/Whatsapp.svg';
import Instagram from '../assets/images/social/Instagram.svg';
import LinkedIn from '../assets/images/social/Linkedin.svg';
import Twitter from '../assets/images/social/Twitter.svg';
import YouTube from '../assets/images/social/Twitter.svg';

const { height, width } = Dimensions.get('window')

const Footer = ({ animatedValue, pageNumber }) => {

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  const { width, height } = dimensions;

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize);

    //firebase.analytics();
    // eslint-disable-next-line
  }, [width, height]);




const styles = StyleSheet.create({
  container: {
    backgroundColor: colours.greyLight,
    borderTopWidth: 1,
    borderTopColor: colours.greyThird,
  },
  containerInside: {
   flex:1,
   width:width<1280?'100%':1280,
   alignSelf:'center'
  },
  containerOne: {
   // flex: 1,
    flexDirection: width > 1000 ? 'row' : 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop:20
  },
  containerTwo: {
   // flex: 1,
    flexDirection: width > 1000 ? 'row' : 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  containerLogo: {
    paddingHorizontal: 10,
  },
  containerSocial: {
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  containerPrivacy: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10,
    paddingTop:width>1000?0:10
  },

  containerCopyright: {
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10

  },
  footerText: {
    fontSize: width > 1000 ? 12 : 10,
    color: colours.blackSecondary,
    textAlign: 'center',
    fontWeight: '300',
    fontFamily: fonts.primary
  },

  footerLink: {
    fontSize: width > 1000 ? 12 : 10,
    fontWeight: '300',
    fontFamily: fonts.primary
  },

  containerIcon: {
    marginHorizontal: 4,
    alignItems: 'center'
  },

  textFooterLinks: {
    fontSize: width > 1000 ? 12 : 10,
    lineHeight: 27,
    textAlign: 'center',
    fontWeight: '300',
    fontFamily: fonts.primary,
    paddingHorizontal: 20,
  },
});

  return (
    <View>
      <FooterWidgets />

      <View style={styles.container}>
        <View style={styles.containerInside}>

          <View style={styles.containerOne}>

            <View style={styles.containerLogo}>
              <Image source={SoknaLogo} style={{width:pageNumber > 0 ? 130 : 170, height:60,resizeMode:'contain' }} />
            </View>

{/* 
            <View style={styles.containerSocial}>
              <TouchableOpacity onPress={() => Linking.openURL('https://www.linkedin.com/company/soknarealestate')}  >
                <View style={styles.containerIcon}> <Image src={LinkedIn} width={30} /> </View>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => Linking.openURL('https://facebook.com/soknarealestateuae')} >
                <View style={styles.containerIcon}> <Image source={Facebook} style={{width:30}}/></View>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => Linking.openURL('https://twitter.com/soknarealestate')}>
                <View style={styles.containerIcon}>  <Image source={Twitter} style={{width:30}}/></View>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => Linking.openURL('https://instagram.com/soknarealestate')} >
                <View style={styles.containerIcon}>  <Image source={Instagram} style={{width:30}}/></View>
              </TouchableOpacity>
              
              <TouchableOpacity onPress={() => Linking.openURL('https://www.youtube.com/@soknarealestate')} >
                <View style={styles.containerIcon}>  <Image source={YouTube} style={{width:30}} /></View>
              </TouchableOpacity>
            </View> */}
            
          </View>
          <View style={styles.containerTwo}>

            <View style={styles.containerPrivacy}>
              <TouchableOpacity onPress={() => navigation.navigate('Privacy')}>
                <Text style={styles.textFooterLinks} >
                  Privacy Policy
                </Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => navigation.navigate('Terms')}>
                <Text style={styles.textFooterLinks} >
                  Business Terms
                </Text>
              </TouchableOpacity>
            </View>

            <View style={styles.containerCopyright}>
              <Text numberOfLines={1} style={styles.footerText} >
                © Copyright  {new Date().getFullYear()} | SOKNA REAL ESTATE BROKERAGE LLC
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginBottom: width>1275?20:60 }} />

      </View>

    </View>

  );
};




export default Footer;