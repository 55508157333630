import React from 'react';
import HomeNavigator from './pages/homeNavigator';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Providers from './providers/providers';

const App = () => {
      return (
           
            // <SafeAreaProvider> <HomeNavigator /></SafeAreaProvider>
            <SafeAreaProvider> <Providers /></SafeAreaProvider>

      )
}
export default App ;


