import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import { StyleSheet, View, ScrollView, Image, Text, Animated, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation } from '@react-navigation/native';
import fonts from '../../config/fonts';

import WhatsApp from '../../assets/images/social/WhatsappIcon.svg';
import Call from '../../assets/images/social/call.svg';
import Mail from '../../assets/images/social/mail.svg';
import LeadFormFill from '../promotions/leadFormFill';
import Floating from '../../components/floating';



const { height, width } = Dimensions.get('window')

const Careers = ({ route }) => {


    const [dimensions, setDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth })
    const handleResize = () => { setDimensions({ width: window.innerWidth, height: window.innerHeight, }); }
    const { width, height } = dimensions;

    useEffect(() => {
        window.scrollTo(0, 0),
            window.addEventListener("resize", handleResize, false)
        return () => window.removeEventListener("resize", handleResize)

        //firebase.analytics();
        // eslint-disable-next-line
    }, [width, height]);

    const [pageNumber, setPageNumber] = useState(0)
    const offset = useRef(new Animated.Value(0)).current;

    const navigation = useNavigation();



    const styles = StyleSheet.create({
        containerScrollView: {
            flex: 1,
            marginTop: width > 999 ? pageNumber > 0 ? 60 : 78 : pageNumber > 0 ? 40 : 65
        },
        containerRight: {
            flex: 2,
            marginVertical: 10,
            paddingVertical: 10,
        },

        containerInsideHeaderRight: {
            borderBottomWidth: 1,
            marginBottom:7,
            borderColor: colours.primaryLighter,
            marginHorizontal: width > 1000 ? 16 : 10,
           // alignSelf:'flex-start'
           // backgroundColor: colours.blueGreyLight,
        },
        textHeader: {
            paddingHorizontal: 10,
            paddingVertical:5,
            color: colours.secondary,
            fontSize: 20,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'justify',
        },


     
        textSubmit: {
            paddingHorizontal: 20,
            alignContent: 'center',
            justifyContent: 'center',
            paddingTop: 10,
            fontSize: 20,
            color: colours.white,
            fontFamily: fonts.primary

        },


        containerBody: {
            width: width > 1080 ? 1280 : width,
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignSelf: 'center',
        },

        containerBodyMobile: {
            flex: 1,
            alignItems: 'center'
        },
        containerBodyPrimary: {
            width: width,
            alignSelf: 'center',
            backgroundColor: colours.blueGreyLight

        },
        containerBodySecondary: {
            width: width,
            alignSelf: 'center',
            backgroundColor: colours.white
        },

        containerDetailsRow: {
            width: width > 1275 ? 1280 : width,
            alignSelf: 'center',
            flexDirection: width > 1000 ? 'row' : 'column',
        },

        containerDetails: {
            flex: 2,
            marginVertical: 15,
            marginHorizontal: width > 1275 ? 15 : 10,
            alignSelf: 'flex-start',
            backgroundColor: colours.white,
            borderRadius: 5,
        },
        containerDetailsMobile: {
            marginVertical: 15,
            marginHorizontal: 15,
            alignSelf:width > 1275 ?'flex-start':'stretch',
            backgroundColor: colours.white,
            borderRadius: 5,
        },

        containerForm: {
            flex: width > 1280 ? 1 : 1,
            marginVertical: 15,
            marginHorizontal: 15,
            backgroundColor: colours.white,
            borderRadius: 5,
            alignSelf:width > 1275 ?'flex-start':'stretch'

        },



        containerCard: {
            borderRadius: 5,
            borderBottomWidth: 2,
            //borderRightWidth: 3,
            borderColor: colours.primary,
            backgroundColor: colours.white,
            width: width > 1275 ? 300 : 200,
            height: width > 1275 ? 340 : 250,
            marginHorizontal: 55,
            marginBottom: 25,

            shadowColor: colours.black,
            shadowOffset: { width: 0, height: 3, },
            shadowOpacity: 0.2,
            shadowRadius: 20,
            elevation: 5,
        },

        containerCardImage: {
            overflow: 'hidden',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            height: width > 1275 ? 250 : 160,
        },
        containerBulletPoints: {
            flexDirection: 'column'
        },
        containerHeaderRow: {
            // width: '100%',
            flexDirection: 'row',
            //justifyContent: 'space-between',
            alignSelf: 'flex-start'
        },
        textH1: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: width > 1275 ? 20 : 20,
            paddingBottom: 5,
            color: colours.secondary,
            fontSize: width > 1275 ? 30 : 22,
            fontWeight: width > 1275 ? '500' : '600',
            fontFamily: fonts.primary,
            textAlign: 'left',

        },
        textH2: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: 20,
            paddingBottom: 5,
            color: colours.primaryDark,
            fontSize: width > 1275 ? 25 : 17,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },
        textParagraph: {
            paddingHorizontal: 20,
            paddingBottom: 15,
            color: colours.black,
            fontWeight: '300',
            fontSize: width > 1275 ? 16 : 14,
            textAlign: 'left',
            fontFamily: fonts.primary,
            lineHeight: width > 1275 ? 30 : 24,
        },
        textParagraphBold: {
            paddingHorizontal: 20,
            paddingBottom: 15,
            color: colours.black,
            fontWeight: '500',
            fontSize: width > 1275 ? 16 : 14,
            textAlign: 'left',
            fontFamily: fonts.primary,
            lineHeight: width > 1275 ? 30 : 24,
        },


        submitButton: {
            width: '90%',
            flexDirection: 'row',
            backgroundColor: '#25D366',
            alignSelf: 'center',
            justifyContent: 'center',
            paddingHorizontal: 20,
            paddingVertical: 5,
            borderRadius: 5,

        },

    });





    return (
        <View style={{ height: height, backgroundColor: colours.white }}>
            {/* HEADER SECTION */}
            <Header animatedValue={offset} pageNumber={pageNumber} />
            <Floating animatedValue={offset} pageNumber={pageNumber} />

            <ScrollView
                style={styles.containerScrollView}
                contentContainerStyle={{ flexGrow: 1 }}
                //pagingEnabled={true}
                showsVerticalScrollIndicator={false}
                scrollEventThrottle={16}
                onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: offset } } }], { useNativeDriver: false, listener: event => { const offsetY = event.nativeEvent.contentOffset.y; setPageNumber(offsetY / height) } })}
            >
                {/* BANNER SECTION */}
                <View style={styles.containerBodySecondary}>
                    <View style={{ marginVertical: width > 1275 ? 20 : 5 }} />
                    <View style={width > 999 ? styles.containerBody : styles.containerBodyMobile}>

                        <View style={styles.containerHeaderRow}>
                            <Text style={[styles.textH1, { color: colours.primary, fontSize: width > 1275 ? 50 : 25 }]}>
                                {'Join Our Team'}
                            </Text>
                        </View>
                    </View>


                </View>

                <View style={styles.containerBodyPrimary}>


                    <View style={styles.containerDetailsRow}>

                        <View style={width > 1275 ? styles.containerDetails : styles.containerDetailsMobile}>


                            <View style={{ marginVertical: width > 1275 ? 20 : 5 }} />

                            {/*HIGHLIGHTS*/}
                            <View style={styles.containerBulletPoints}>
                                <Text style={styles.textH2}>
                                
                                    {'Looking for A Career in Real Estate?'}

                                </Text>

                                <View style={{ marginVertical: 20, paddingLeft: 20 }}>

                                    <View style={styles.containerRight}>
                                        <View style={styles.containerInsideHeaderRight}>
                                            <Text style={styles.textHeader}>{'MAKE A DIFFERENCE'}</Text>
                                        </View>

                                        <Text style={[styles.textParagraphBold, { color: colours.secondaryDarker }]}>
                                            {'Here at Sokna Real Estate , we believe that the best way to predict the future is to create it.\n '}
                                        </Text>

                                    </View>

                                    <View style={styles.containerRight}>
                                        <View style={styles.containerInsideHeaderRight}>
                                            <Text style={styles.textHeader}>{'BE PART OF A PROGRESSIVE WORK CULTURE'}</Text>
                                        </View>

                                        <Text style={[styles.textParagraphBold, { color: colours.secondaryDarker }]}>
                                        {"At Sokna Real Estate , we aren't just a team; we are close-knit family. We work hard and play hard.\n We celebrate every team member, every milestone, and every achievement. Offering a friendly and fun work-environment, Sokna Real Estate  invests in each member of the team in terms of time and training sessions.\n If you are looking for the next growth period in your career, Sokna Real Estate  is your calling. Will you answer?"}
                                        </Text>

                                    </View>


                                    <View style={styles.containerRight}>
                                        <View style={styles.containerInsideHeaderRight}>
                                            <Text style={styles.textHeader}>{'APPLY ONLINE'}</Text>
                                        </View>

                                        <Text style={[styles.textParagraphBold, { color: colours.secondaryDarker }]}>
                                        {'FIND YOUR PLACE WITH US.- Send your CV to hr@soknarealestate.com'}
                                        </Text>

                                    </View>

                                    <View style={styles.containerRight}>
                                        <View style={styles.containerInsideHeaderRight}>
                                            <Text style={styles.textHeader}>{'Current Openings'}</Text>
                                        </View>

                                        <Text style={[styles.textParagraphBold, { color: colours.secondaryDarker }]}>
                                        {'Real Estate Sales Agents (Chinese or Russian Speaking'}
                                        </Text>

                                    </View>


                                </View>
                            </View>

     


                            <View style={{ marginVertical: 10 }} />


                        </View>




                        

                        <View style={styles.containerForm}>


                            <Text style={styles.textH2}>
                                {'Connect With Us'}
                            </Text>
                            <Text style={[styles.textParagraph, { color: colours.secondaryDarker }]}>
                                {'Request A Callback'}
                            </Text>
                            <LeadFormFill pageName={'About'} />

                            <View style={{ marginVertical: 10, borderBottomColor:colours.blueGreyLight, borderBottomWidth:15}} />

                            <Text style={styles.textH2}>
                                {'Chat With Us'}
                            </Text>
                            <Text style={[styles.textParagraph, { color: colours.secondaryDarker }]}>
                                {'Start Converstation in WhatsApp'}
                            </Text>



                            <TouchableOpacity style={styles.submitButton} onPress={() => Linking.openURL('https://api.whatsapp.com/send?phone=971568225126')} >
                                <View> <Image source={WhatsApp} style={{ width: 40, height: 40 }} /></View>
                                <View><Text style={styles.textSubmit}>{'Start Chat'}</Text>  </View>
                            </TouchableOpacity>

                            <View style={{ marginVertical: 20 }} />

                        </View>
                    </View>
                </View>














                {/* FOOTER SECTION */}
                <Footer animatedValue={offset} pageNumber={pageNumber} />
            </ScrollView>
        </View>

    );
};




export default Careers;




