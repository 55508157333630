//import * as firebase from 'firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDmhMvk2bK5F8VUbNgVI0AOyZ4KFsdzIgU",
  authDomain: "sokna-re-website.firebaseapp.com",
  projectId: "sokna-re-website",
  storageBucket: "sokna-re-website.appspot.com",
  messagingSenderId: "1036469981084",
  appId: "1:1036469981084:web:a4cc6476a0b6c4979c0259",
  measurementId: "G-30QRND61CD"
};

const firebaseConfig2 = {
  apiKey: "AIzaSyCWd_gb0pMW1QzJGNmt_0PDyafwEmmMHPM",
  authDomain: "sokna-crm.firebaseapp.com",
  projectId: "sokna-crm",
  storageBucket: "sokna-crm.appspot.com",
  messagingSenderId: "971351817572",
  appId: "1:971351817572:web:9ed181b25865a73f88ce71",
  measurementId: "G-Q5Q2VPDN3P"
};


// if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  var leadfirebase = firebase.initializeApp(firebaseConfig2,'sokna-crm');

// }

export { firebase,leadfirebase };